import React from "react";
import { MenuComponentProps } from "../types";
import { FileSystem, Screen, Menu } from "../";
import { saveAs } from "file-saver";
import { Auth } from "../../../contexts/ridersUserContext";
import {FormattedMessage} from "react-intl";

const MainMenu: React.FC<MenuComponentProps> = ({ clickAndCloseHandler }) => {
  const {
    setWorkspaceCounter,
    setWorkspacelayout,
    setIsTerminalOpen,
    changeFileInWorkspace,
    getWorkspaceAndFileId,
    project,
    godotContainerRef,
    refreshFunction,
  }: any = React.useContext(Screen.context);

  const { readFile, writeFile }: any = React.useContext(FileSystem.context);

  const { setProjectType, userCodeFilePath }: any = React.useContext(
    Auth.context
  );

  var fileSelector: HTMLInputElement | undefined = undefined;

  const handleUpload = (
    onFileLoaded: (e: ProgressEvent<FileReader>) => void
  ) => {
    if (typeof fileSelector === "undefined") {
      fileSelector = document.createElement("input");
      fileSelector.type = "file";
      fileSelector.style.display = "none";
      document.body.appendChild(fileSelector);
    }
    fileSelector.onchange = (e) => {
      if (!fileSelector?.files || fileSelector?.files?.length !== 1) return;

      let reader = new FileReader();
      reader.readAsText(fileSelector?.files[0], "UTF-8");

      reader.onload = onFileLoaded;

      reader.onerror = (e) => {
        // message.error("File could not imported", 2);
      };

      document.body.removeChild(fileSelector);
    };

    fileSelector.click();
  };

  const handleDownload = async (file_path: string, save_name: string) => {
    const code = await readFile(file_path);
    console.log(code);
    const blob = new Blob([code], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blob, save_name);
  };

  return (
    <Menu>
      {[
        {
          title: <FormattedMessage id="userCodeSubMenu" />,
          items: [
            {
              title: <FormattedMessage id="userCodeDownloadAction" />,
              onClick: clickAndCloseHandler!(() => {
                handleDownload(
                  `/${
                    project.dev === "blockly" ? "blockly.xml" : userCodeFilePath
                  }`,
                  `${project.name}_${
                    project.dev === "blockly" ? "blockly.xml" : "usercode.py"
                  }`
                );
              }),
            },
            {
              title: <FormattedMessage id="userCodeUploadAction" />,
              onClick: clickAndCloseHandler!(() => {
                handleUpload(async (e) => {
                  const data = e?.target?.result;
                  writeFile(
                    `${
                      project.dev === "blockly"
                        ? "/blockly.xml"
                        : userCodeFilePath
                    }`,
                    data
                  );
                  //settimeout
                  setTimeout(() => {
                    refreshFunction();
                  }, 1000);

                  // const { workspaceId, fileId } = getWorkspaceAndFileId(
                  //   `${
                  //     project.dev === "blockly" ? "Block Coding" : "usercode.py"
                  //   }`,
                  // );
                  // console.log(workspaceId, fileId);
                  // changeFileInWorkspace(workspaceId, fileId, data);
                  //window.location.reload();
                });
              }),
            },
          ],
        },
        {
          title: <FormattedMessage id="scenarioSubMenu" />,
          items: [
            {
              title: <FormattedMessage id="scenarioDownloadAction" />,
              onClick: clickAndCloseHandler!(() => {
                const message = JSON.stringify({
                  action: "downloadScenario",
                  project: project.scenario_name,
                });
                godotContainerRef.current?.contentWindow.postMessage(
                  message,
                  "*"
                );
                setTimeout(() => {
                  const scenario = localStorage.getItem(project.scenario_name);
                  if (scenario) {
                    const blob = new Blob([scenario], {
                      type: "text/plain;charset=utf-8",
                    });
                    saveAs(blob, `${project.scenario_name}.txt`);
                  }
                }, 500);
              }),
            },
            {
              title: <FormattedMessage id="scenarioLoadAction" />,
              onClick: clickAndCloseHandler!(() => {
                handleUpload(async (e) => {
                  console.log(String(e?.target?.result));
                  const message = JSON.stringify({
                    action: "updateScenario",
                    project: project.scenario_name,
                    scenario: String(e?.target?.result),
                  });
                  godotContainerRef.current?.contentWindow.postMessage(
                    message,
                    "*"
                  );
                  setTimeout(() => {
                    refreshFunction();
                  }, 1000);
                });
              }),
            },
            {
              title: <FormattedMessage id="scenarioClearAction" />,
              onClick: clickAndCloseHandler!(() => {
                const message = JSON.stringify({
                  action: "clearScenario",
                  project: project.scenario_name,
                });
                godotContainerRef.current?.contentWindow.postMessage(
                  message,
                  "*"
                );
                window.location.reload();
              }),
              shortcut: "⌥⇧D",
            },
          ],
        },
        project.robot_name
          ? {
              title: <FormattedMessage id="robotSubMenu" />,
              items: [
                {
                  title: <FormattedMessage id="robotDownloadAction" />,
                  onClick: clickAndCloseHandler!(() => {
                    const message = JSON.stringify({
                      action: "downloadRobot",
                      robot: project.robot_name,
                    });
                    godotContainerRef.current?.contentWindow.postMessage(
                      message,
                      "*"
                    );
                    setTimeout(() => {
                      const robot = localStorage.getItem(project.robot_name);
                      if (robot) {
                        const blob = new Blob([robot], {
                          type: "text/plain;charset=utf-8",
                        });
                        saveAs(blob, `${project.robot_name}.json`);
                      }
                    }, 500);
                  }),
                },
                {
                  title: <FormattedMessage id="robotLoadAction" />,
                  onClick: clickAndCloseHandler!(() => {
                    handleUpload(async (e) => {
                      console.log(String(e?.target?.result));
                      const message = JSON.stringify({
                        action: "updateRobot",
                        name: "Mars_Robot_New",
                        robot: String(e?.target?.result),
                      });
                      godotContainerRef.current?.contentWindow.postMessage(
                        message,
                        "*"
                      );
                      window.location.reload();
                    });
                  }),
                },
              ],
            }
          : { disabled: true },
        {
          title: <FormattedMessage id="terminalAction" />,
          onClick: clickAndCloseHandler!(() => {
            setIsTerminalOpen(true);
          }),
        },
        {},
        // {
        //   title: "New Workspace",
        //   onClick: clickAndCloseHandler!(() => {
        //     setWorkspaceCounter(1);
        //     setWorkspacelayout(`"A A" "A A"`);
        //   }),
        // },
        // {
        //   title: "theme options",
        //   items: [
        //     {
        //       title: "light",
        //       onClick: clickAndCloseHandler!(() => {
        //         setProjectType("electronics");
        //         document
        //           .getElementsByTagName("html")[0]
        //           .classList.remove("dark");
        //       }),
        //     },
        //     {
        //       title: "dark",
        //       onClick: clickAndCloseHandler!(() => {
        //         setProjectType("robotics");
        //         document.getElementsByTagName("html")[0].classList.add("dark");
        //       }),
        //     },
        //   ],
        // },
        // {
        //   title: "workspace options",
        //   items: [
        //     {
        //       title: "whole workspace",
        //       onClick: clickAndCloseHandler!(() => {
        //         setWorkspaceCounter(1);
        //         setWorkspacelayout(`"A A" "A A"`);
        //       }),
        //     },
        //     {
        //       title: "split horizontal",
        //       onClick: clickAndCloseHandler!(() => {
        //         setWorkspaceCounter(2);
        //         setWorkspacelayout(`"A A" "B B"`);
        //       }),
        //       shortcut: "⌥⇧D",
        //     },
        //     {
        //       title: "split vertical",
        //       onClick: clickAndCloseHandler!(() => {
        //         setWorkspaceCounter(2);
        //         setWorkspacelayout(`"A B" "A B"`);
        //       }),
        //       shortcut: "⌥⇧D",
        //     },
        //     {
        //       title: "quartered",
        //       onClick: clickAndCloseHandler!(() => {
        //         setWorkspaceCounter(4);
        //         setWorkspacelayout(`"A B" "C D"`);
        //       }),
        //       shortcut: "⌥⇧D",
        //     },
        //     {
        //       title: "2+1 horizontal",
        //       onClick: clickAndCloseHandler!(() => {
        //         setWorkspaceCounter(3);
        //         setWorkspacelayout(`"A B" "C C"`);
        //       }),
        //       shortcut: "⌥⇧D",
        //     },
        //   ],
        // },
        // {},
        // {
        //   title: "Close Workspace",
        //   onClick: clickAndCloseHandler!(() => {
        //     setWorkspaceCounter(0);
        //     setWorkspacelayout(`"A A" "A A"`);
        //   }),
        // },
        // {},
        // {
        //   title: "Settings",
        //   onClick: clickAndCloseHandler!(() => console.log("settings")),
        // },
      ]}
    </Menu>
  );
};

export default MainMenu;

import React from "react";

export const PlaceholderArea: React.FC<{
  mousePosition: "left" | "right" | "bottom" | undefined;
}> = ({ mousePosition }) => {
  return (
    <>
      <div
        className={`h-full p-2 w-1/4 absolute top-0 bg-riders-green opacity-30 transition-all ${
          mousePosition === "right" ? "right-0" : "-right-1/4"
        }`}
      />
      <div
        className={`h-1/4 p-2 w-full absolute left-0 bg-riders-green opacity-30 transition-all ${
          mousePosition === "bottom" ? "bottom-0" : "-bottom-1/4"
        }`}
      />
    </>
  );
};

import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { verifyEdusimToken } from "../api";

export const RedirectPage = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token") || "";

  const [path, setPath] = useState<string>("");

  useEffect(() => {
    (async () => {
      const data = (await verifyEdusimToken(encodeURIComponent(token))) as any;
      const uri = `/${data.accessToken}?community=edusim`;
      setPath(uri);
    })();
  }, []);

  if (path !== "") return <Navigate to={path} replace />;

  return <div>Redirecting...</div>;
};

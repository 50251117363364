import React, { useState, useEffect } from "react";
import { ICollapseComponentProps } from "../types";
import { AiOutlineDown } from "react-icons/ai";

export const Collapse: React.FC<ICollapseComponentProps> = ({
  children,
  title,
  open = false,
  hasBorder = false,
  className,
  icon = null,
  height = "auto",
  hasAnimation = false,
  onClick,
}) => {
  const [openState, setOpenState] = useState(open || false);

  useEffect(() => {
    setOpenState(open);
  }, [open]);

  return (
    <div
      className={`flex flex-col py-0.5 border-vs-light-border dark:border-vs-dark-border cursor-pointer ${
        hasBorder ? "border-b" : ""
      }`}
    >
      <div
        className="flex text-xs items-center px-1"
        onClick={() => {
          onClick ? onClick() : setOpenState(!openState);
        }}
      >
        {icon === false
          ? null
          : icon || (
              <span>
                <AiOutlineDown
                  className={`transition-all ${
                    openState === true ? "rotate-0" : "-rotate-90"
                  }`}
                />
              </span>
            )}
        <span className="pl-0.5">{title}</span>
      </div>
      <div
        className={`overflow-hidden hover:overflow-y-scroll scrollbar-hidden px-4 ${
          hasAnimation ? "transition-height" : ""
        } ${className}`}
        style={{ height: openState === true ? height : 0 }}
      >
        {children}
      </div>
    </div>
  );
};

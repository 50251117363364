import axios from "axios";
import { requestWithMaxReties } from "./utils/requestWithMaxRetries";

import projectJson from "./local.json";

// if basepath === editor.riders.ai then use prod api, else if basepath === localhost then use dev api else use staging api
let API_URL = process.env.REACT_APP_PROD_API_URL;
if (window.location.hostname === "localhost") {
  API_URL = process.env.REACT_APP_DEV_API_URL;
} else if (window.location.hostname === "editor.riders.ai") {
  API_URL = process.env.REACT_APP_PROD_API_URL;
} else if (window.location.hostname === "editor-staging.riders.ai") {
  API_URL = process.env.REACT_APP_STAGING_API_URL;
} else {
  API_URL = process.env.REACT_APP_DENEYAP_API_URL;
}

const isLocal = window.location.hostname === "localhost";

export const restartSession = async (token: string) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const { data } = await axios.get(`${API_URL}/api/auth/restart`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          authorization: `Token ${token}`,
        },
      });

      resolve(data.accessToken);
    } catch (error) {
      reject(error);
    }
  });
};

export const collectAchievement = async (
  token: string,
  achievementId: string
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const { data } = await axios.post(
        API_URL + "/api/achievements/achieve/",
        {
          data: achievementId,
        },
        {
          headers: {
            authorization: `Token ${token}`,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getAchievements = async (token: string) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const { data } = await axios.get(API_URL + "/api/achievements/list", {
        headers: {
          authorization: `Token ${token}`,
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getScore = async (token: string, results: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      await axios.post(
        API_URL + "/api/score",
        {
          data: results,
        },
        {
          headers: {
            authorization: `Token ${token}`,
          },
        }
      );
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};

export const publishBranch = async (token: string, message: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      await axios.post(
        API_URL + "/api/branches/publish",
        {
          message: message,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};

export const verifyEdusimToken = async (token: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/api/auth/verify/edusim?token=${token}`
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const verifyToken = async (token: string) => {
  if (token === "playground" && isLocal) {
    return projectJson;
  }

  return new Promise<any>(async (resolve, reject) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/api/auth/verify?token=${token}`
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const refreshToken = async (token: string) => {
  if (token === "playground" && isLocal) {
    return {
      accessToken: "playground",
      status: "verified",
    };
  }
  return new Promise<any>(async (resolve, reject) => {
    try {
      const random = Math.random().toString(36).substring(7);
      const { data } = await requestWithMaxReties({
        method: "get",
        url: `${API_URL}/api/auth/refresh?token=${token}&random=${random}`,
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const idleShutDown = async (token: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      await axios.get(`${API_URL}/api/auth/idle`, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Token ${token}`,
        },
      });
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};

export const storagePush = async (
  token: string,
  file: string,
  content: string | null
) => {
  return new Promise(async (resolve, reject) => {
    try {
      await axios.post(
        `${API_URL}/api/storage/push`,
        {
          filePath: file,
          fileContent: content,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};

export const getActiveProxyServerAddress = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/api/settings/active-proxy-server`
      );
      resolve(data.value);
    } catch (error) {
      reject(error);
    }
  });
};
import { FC, useContext, useEffect, useState } from "react";
import Blockly from "blockly/core";
import {
  BlocklyWorkspace,
  ToolboxDefinition,
  WorkspaceSvg,
} from "react-blockly";
import cn from "classnames";
// import Theme from "@blockly/theme-modern";
import { pythonGenerator } from "blockly/python";
import { IBlocklyProps } from "../types";
import workspaceConfiguration from "./workspaceConfiguration.json";
import { Screen } from "../helpers/Screen";
import { FileSystem } from "../helpers/FileSystem";
import { updateFlyOut, injectLibFromStack } from "./OverrideMsgs";

import ArduinoBlockly from "./generator";

import { scriptUrls } from "./constants";
import * as Tr from "blockly/msg/tr";

const urlParams = new URLSearchParams(window.location.search);
var ApiUrl = process.env.REACT_APP_CDN_URL;
if (window.location.hostname.includes("editor-staging.riders.ai")) {
  ApiUrl = process.env.REACT_APP_STAGING_CDN_URL;
}

if (
  window.location.hostname.includes("localhost") &&
  urlParams.has("localcdn")
) {
  ApiUrl = "/cdn";
}

export const BEditor: FC<IBlocklyProps> = (props) => {
  const { className, setCode, setXML, project, file_path } = props;

  const { refreshKey } = useContext<any>(Screen.context);
  const { readFile } = useContext<any>(FileSystem.context);

  const [userXML, setUserXML] = useState<string | undefined>(undefined);

  useEffect(() => {
    setUserXML(undefined);
    readFile(file_path).then((code: string) => {
      setUserXML(code);
    });
  }, [refreshKey]);

  const [toolboxCategories, setToolboxCategories] = useState<
    any | ToolboxDefinition
  >({
    kind: "categoryToolbox",
    contents: [],
  }); // Empty initial toolbox, if it will not find toolboxCategories.json

  const workspaceChange = (workspace: WorkspaceSvg) => {

    window.getBlocklyWorkspace = (CB) => {
      CB(workspace);
    };

    var code = "";
    try {
      if (project?.dev === "blockly") {
        if (
          window.Blockly.Arduino === undefined ||
          window.Blockly === undefined
        ) {
          return;
        }
        code = window.Blockly.Arduino.workspaceToCode(workspace);
      } else {
        code = pythonGenerator.workspaceToCode(workspace);
      }

      updateFlyOut(Blockly, workspace);
    } catch (error) {
      console.log(error + "Error Code " + project?.dev);
    }
    setCode(code);
    console.log(code);
    try {
      const _panel: SVGAElement | null = document.querySelector(
        "#blockly_wrapper > div > div > svg:nth-child(7)"
      );
      if (_panel && _panel.style.display === "none")
        (document.querySelector(
          "#blockly_wrapper > div > div > svg:nth-child(8)"
        ) as SVGAElement)!.style.transform = "";
    } catch (ignored) {}

    const scrollbarArea: any = document.getElementsByClassName(
      "blocklyFlyoutScrollbar"
    )[1];
    const flyout = document.getElementsByClassName("blocklyFlyout")[1];
    const flyoutScrollBar = document
      .getElementsByClassName("blocklyFlyoutScrollbar")[1]
      .querySelector(".blocklyScrollbarHandle");
    if (flyout && flyoutScrollBar) {
      if (
        parseFloat(flyout.getAttribute("height") as string) -
          parseFloat(flyoutScrollBar.getAttribute("height") as string) ===
        5
      ) {
        scrollbarArea.style.display = "none";
      } else {
        scrollbarArea.style.display = "block";
      }
    }
  };

  const onXmlChange = (newXML: string) => {
    setXML(newXML);
  };

  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    fetch(`/arduino-blockly/toolbox.json`)
      .then((res) => res.json())
      .then((data) => {
        setToolboxCategories(data);
      });
  }, [project]);

  useEffect(() => {
    async function fetchBlockData(toolboxName: string) {
      try {
        window.Blockly = Blockly;
        window.Blockly.Arduino = ArduinoBlockly;

        injectLibFromStack(scriptUrls, "/arduino-blockly/");
        injectLibFromStack(["lang/tr.js"], "/arduino-blockly/");

        setTimeout(setScriptLoaded, 1000, true);
      } catch (error) {
        console.error(error);
      }
    }
    fetchBlockData(project.toolboxName);
  }, [project]);

  useEffect(() => {
    if (window.blocklyThemeLoaded) return;

    if (project.blocklyTheme === "zelos") {
      Blockly.setLocale(Tr);
    }

    Blockly.Theme.defineTheme("riders", {
      name: "riders",
      base: Blockly.Themes.Classic,

      componentStyles: {
        workspaceBackgroundColour: "rgb(169,186,201)",
        toolboxBackgroundColour: "rgb(220,225,229)",
        toolboxForegroundColour: "#000",
        flyoutBackgroundColour: "rgb(220,225,229)",
        flyoutForegroundColour: "#fff",
        flyoutOpacity: 0.75,
        scrollbarColour: "rgb(255,87,1)",
        scrollbarOpacity: 1,
      },

      fontStyle: {},
      startHats: false,
    });

    window.blocklyThemeLoaded = true;
  }, []);

  if (!scriptLoaded || !project) {
    return (
      <div className={className} id="blockly_wrapper">
        <div className="h-full flex flex-col justify-center items-center">
          <span className="mb-6 text-2xl">Loading Block Coding</span>
          <div style={{ display: "flex" }}>
            <div className="dot dot--one"></div>
            <div className="dot dot--two"></div>
            <div className="dot dot--three"></div>
            <div className="dot dot--four"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={cn(className, "deneyap-theme")}
      id="blockly_wrapper"
      key={refreshKey}
    >
      {userXML && (
        <BlocklyWorkspace
          className="h-full"
          toolboxConfiguration={toolboxCategories}
          workspaceConfiguration={{
            media: workspaceConfiguration.media,
            renderer: "geras",
            zoom: {
              ...workspaceConfiguration.zoom,
            },
            theme: "riders",
            move: {
              scrollbars: {
                horizontal: true,
                vertical: true,
              },
              drag: true,
              wheel: true,
            },
          }}
          initialXml={userXML}
          onInject={workspaceChange}
          onWorkspaceChange={workspaceChange}
          onXmlChange={onXmlChange}
        />
      )}
    </div>
  );
};

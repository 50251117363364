// ScriptURLs to implement BlocklyDuino properly
export const scriptUrls = [
  "riders-blocks/default.js",
  "riders-blocks/structure.js",
  "riders-blocks/io.js",
  "riders-blocks/board.js",
  "riders-blocks/loop.js",
  "riders-blocks/logic.js",
  "riders-blocks/math.js",
  "riders-blocks/text.js",
  "riders-blocks/variable.js",
  "riders-blocks/functions.js",
  "blocks/rtc.js",
  "riders-blocks/array.js",
  "riders-blocks/step_motor.js",
  "riders-blocks/servo.js",
  "blocks/lcd.js",
  "riders-blocks/audio.js",
  "riders-blocks/serial.js",
  "riders-blocks/sensors.js",
  "blocks/rgb_led.js",
  "blocks/remote.js",
  "blocks/rfid.js",
  "blocks/keypad.js",
  "blocks/sd_card.js",
  "blocks/iot.js",
  "blocks/matrix.js",
  "blocks/oled.js",
  "blocks/deneyap_sensors.js",
];

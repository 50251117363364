import React from "react";

export const TextArea: React.FC<any> = ({
  placeholder,
  rows,
  value,
  onChange,
  block = false,
  className,
}) => {
  return (
    <textarea
      className={`bg-vs-dark-border outline-none px-2 py-1 text-xs ${
        block ? "w-full" : ""
      } ${className}`}
      rows={rows}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    ></textarea>
  );
};

// ScriptURLs to implement BlocklyDuino properly
export const scriptUrls = [
  "blocks/board.js",
  "blocks/logic.js",
  "blocks/time.js",
  "blocks/math.js",
  "blocks/text.js",
  "blocks/variable.js",
  "blocks/functions.js",
  "blocks/io.js",
  "blocks/rtc.js",
  "blocks/array.js",
  "blocks/step_motor.js",
  "blocks/servo.js",
  "blocks/lcd.js",
  "blocks/audio.js",
  "blocks/serial.js",
  "blocks/sensors.js",
  "blocks/rgb_led.js",
  "blocks/remote.js",
  "blocks/rfid.js",
  "blocks/keypad.js",
  "blocks/sd_card.js",
  "blocks/iot.js",
  "blocks/matrix.js",
  "blocks/oled.js",
  "blocks/deneyap_sensors.js",
];

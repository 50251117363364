import {
  Button,
  Collapse,
  ToolbarIcon,
  Screen,
  capitalize,
  TextArea,
  FileSystem,
} from "../";
import { IToolbarExtension, IToolbarExtensionProps } from "../types";
import React, { useContext } from "react";
import { VscGitPullRequest } from "react-icons/vsc";
import { IoIosGitBranch } from "react-icons/io";
import { Auth } from "../../../contexts/ridersUserContext";
import { Alert } from "antd";
import {FormattedMessage} from "react-intl";
import translations from "../../../translations";
import useLanguage from "../../useLanguage";

const Git: React.FC<IToolbarExtensionProps> = ({ panel, setPanel }) => {
  const [alert, setAlert] = React.useState<number>(0);

  const extension: IToolbarExtension = {
    title: "Riders",
    Section: <ExtensionContent setAlertState={setAlert} />,

    toolbarIcon: () => {
      return (
        <ToolbarIcon
          Icon={IoIosGitBranch}
          onClick={() => {
            if (panel.title === extension.title) {
              setPanel({ ...panel, open: !panel.open });
            } else {
              setPanel({
                title: extension.title,
                open: true,
                children: extension.Section,
                settings:
                  extension.Settings && React.createElement(extension.Settings),
              });
            }
          }}
          active={panel.title === "Riders" && panel.open}
        />
      );
    },
  };

  return extension.toolbarIcon({});
};

export default Git;

const ExtensionContent: React.FC<{
  setAlertState: React.Dispatch<React.SetStateAction<number>>;
}> = ({ setAlertState }) => {
  const { project, addFileToWorkspace, getWorkspaceAndFileId } =
    useContext<any>(Screen.context);
  const { publish_branch } = useContext<any>(FileSystem.context);
  const { mode, user } = useContext<any>(Auth.context);

  const [activeTab, setActiveTab] = React.useState<string>("PUBLISH");
  const [publishMessage, setPublishMessage] = React.useState<string>("");
  const [publishing, setPublishing] = React.useState<boolean>(false);

  const {language} = useLanguage(project);

  return (
    <div className="flex flex-col">
      <Collapse
        title={<FormattedMessage
            id="publishExpendableHeader"
        />}
        hasBorder
        hasAnimation
        height="60vh"
        open={activeTab === "PUBLISH"}
        onClick={() => {
          activeTab === "PUBLISH" ? setActiveTab("") : setActiveTab("PUBLISH");
        }}
      >
        {mode === "editor" ? (
          <>
            <TextArea
              rows={3}
              onChange={(e: any) => {
                !publishing && setPublishMessage(e.target.value);
              }}
              value={publishMessage}
              placeholder={translations[language]['publishTextAreaMessage']}
              block
            />
            <Button
              onClick={async () => {
                setPublishing(true);
                await publish_branch(publishMessage);
                setPublishMessage("");
                setPublishing(false);
              }}
              block
              disabled={publishMessage.trim().length === 0 || publishing}
            >
              <FormattedMessage
                  id="publishNewVersionButton"
              />
            </Button>
          </>
        ) : (
          <>
            <Alert
              message="Warning"
              description="Publish Action Can't Be Used in Preview Mode"
              type="warning"
              showIcon
            />
          </>
        )}
      </Collapse>

      {/* <Collapse
        title="BRANCHES"
        hasBorder
        hasAnimation
        height="60vh"
        open={activeTab === "BRANCHES"}
        onClick={() => {
          activeTab === "BRANCHES"
            ? setActiveTab("")
            : setActiveTab("BRANCHES");
        }}
      >
        <div className="flex flex-col pr-4 pt-2">test</div>
      </Collapse> */}
    </div>
  );
};

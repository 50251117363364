export const injectLibFromStack = (
  scriptUrls: Array<String>,
  folderUrl: any
) => {
  if (scriptUrls.length > 0) {
    //grab the next item on the stack
    var nextLib = scriptUrls.shift();

    //create a script tag with this library
    var scriptTag = document.createElement("script");
    scriptTag.src = folderUrl + nextLib;

    console.log(scriptTag.src);

    //when successful, inject the next script
    scriptTag.onload = function (e) {
      injectLibFromStack(scriptUrls, folderUrl);
    };

    //append the script tag to the <head></head>
    document.body.appendChild(scriptTag);
  } else return;
};

export const updateFlyOut = (blocklyObj: any, workspace: any) => {
  var variablesFlyoutCallback = function (workspace: any) {
    var variableList = workspace.getVariablesOfType("");
    console.log(variableList + "VARLIST");
    variableList.sort((a: any, b: any) => {
      var c = String(a).toLowerCase(),
        d = String(b).toLowerCase();
      return c < d ? -1 : c == d ? 0 : 1;
    });
    var xmlList = [];
    var button = document.createElement("button");
    button.setAttribute("text", blocklyObj.Msg.NEW_VARIABLE);
    button.setAttribute("callbackKey", "CREATE_VARIABLE");
    workspace.registerButtonCallback("CREATE_VARIABLE", function (button: any) {
      blocklyObj.Variables.createVariableButtonHandler(
        button.getTargetWorkspace()
      );
    });
    xmlList.push(button);
    if (variableList.length > 0) {
      if (window.localStorage.prog != "python") {
        if (blocklyObj.Blocks["variables_set_init"]) {
          var block = document.createElement("block");
          block.setAttribute("type", "variables_set_init");
          block.setAttribute("gap", "8");
          var field = document.createElement("field");
          field.appendChild(
            blocklyObj.Variables.generateVariableFieldDom(variableList[0])
          );
          field.setAttribute("name", "VAR");
          block.appendChild(field);
          xmlList.push(block);
        }
        if (blocklyObj.Blocks["variables_set"]) {
          var block = document.createElement("block");
          block.setAttribute("type", "variables_set");
          block.setAttribute("gap", "8");
          var field = document.createElement("field");
          field.appendChild(
            blocklyObj.Variables.generateVariableFieldDom(variableList[0])
          );
          field.setAttribute("name", "VAR");
          block.appendChild(field);
          xmlList.push(block);
        }
        if (blocklyObj.Blocks["math_change"]) {
          var block = document.createElement("block");
          block.setAttribute("type", "math_change");
          block.setAttribute("gap", "8");
          var field = document.createElement("field");
          field.appendChild(
            blocklyObj.Variables.generateVariableFieldDom(variableList[0])
          );
          field.setAttribute("name", "VAR");
          block.appendChild(field);
          xmlList.push(block);
        }
        if (blocklyObj.Blocks["variables_const"]) {
          var block = document.createElement("block");
          block.setAttribute("type", "variables_const");
          block.setAttribute("gap", "8");
          var field = document.createElement("field");
          field.appendChild(
            blocklyObj.Variables.generateVariableFieldDom(variableList[0])
          );
          field.setAttribute("name", "VAR");
          block.appendChild(field);
          xmlList.push(block);
        }
        if (blocklyObj.Blocks["base_define_const"]) {
          var block = document.createElement("block");
          block.setAttribute("type", "base_define_const");
          if (blocklyObj.Blocks["variables_get"]) {
            block.setAttribute("gap", "16");
          }
          var field = document.createElement("field");
          field.appendChild(
            blocklyObj.Variables.generateVariableFieldDom(variableList[0])
          );
          field.setAttribute("name", "VAR");
          block.appendChild(field);
          xmlList.push(block);
        }
        for (var i = 0; i < variableList.length; i++) {
          if (blocklyObj.Blocks["variables_get"]) {
            var block = document.createElement("block");
            block.setAttribute("type", "variables_get");
            if (blocklyObj.Blocks["variables_set"]) {
              block.setAttribute("gap", "8");
            }
            var field = document.createElement("field");
            field.appendChild(
              blocklyObj.Variables.generateVariableFieldDom(variableList[i])
            );
            field.setAttribute("name", "VAR");
            block.appendChild(field);
            xmlList.push(block);
          }
        }
      } else {
        if (blocklyObj.Blocks["variables_set"]) {
          var block = document.createElement("block");
          block.setAttribute("type", "variables_set");
          block.setAttribute("gap", "8");
          var field = document.createElement("field");
          field.appendChild(
            blocklyObj.Variables.generateVariableFieldDom(variableList[0])
          );
          field.setAttribute("name", "VAR");
          block.appendChild(field);
          xmlList.push(block);
        }
        if (blocklyObj.Blocks["math_change"]) {
          var block = document.createElement("block");
          block.setAttribute("type", "math_change");
          block.setAttribute("gap", "8");
          var field = document.createElement("field");
          field.appendChild(
            blocklyObj.Variables.generateVariableFieldDom(variableList[0])
          );
          field.setAttribute("name", "VAR");
          block.appendChild(field);
          xmlList.push(block);
        }
        for (var i = 0; i < variableList.length; i++) {
          if (blocklyObj.Blocks["variables_get"]) {
            var block = document.createElement("block");
            block.setAttribute("type", "variables_get");
            if (blocklyObj.Blocks["variables_set"]) {
              block.setAttribute("gap", "8");
            }
            var field = document.createElement("field");
            field.appendChild(
              blocklyObj.Variables.generateVariableFieldDom(variableList[i])
            );
            field.setAttribute("name", "VAR");
            block.appendChild(field);
            xmlList.push(block);
          }
        }
      }
    }
    console.log(xmlList);

    var ampersand = document.createElement("block");
    ampersand.setAttribute("type", "ampersand");
    xmlList.push(ampersand);

    return xmlList;
  };

  workspace.registerToolboxCategoryCallback(
    "VARIABLE",
    variablesFlyoutCallback
  );
};

import React, { useState, useRef, useEffect, useContext } from "react";
import { Space } from "../shared";
import { VscEllipsis } from "react-icons/vsc";
import { IPanelComponentProps } from "./types";
import { Screen } from "./helpers/Screen";
import { Dropdown } from "./shared/Dropdown";

export const Panel: React.FC<IPanelComponentProps> = ({
  children,
  title,
  settings,
  open = false,
  className,
}) => {
  const { setCursor }: any = useContext(Screen.context);

  const panelRef = useRef(null);
  const [collapsed, setCollapsed] = useState(!open);

  const [width, setWidth] = useState(300);
  const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    if (open) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }, [open]);

  useEffect(() => {
    function handleMouseMove(event: any) {
      if (isResizing) {
        if (event.clientX < 248) {
          setCursor("e-resize");
          setWidth(200);
        } else if (event.clientX > 648) {
          setCursor("w-resize");
          setWidth(600);
        } else {
          setCursor("col-resize");
          setWidth(event.clientX - 46.5);
        }
      }
    }

    function handleMouseUp() {
      setIsResizing(false);
      setCursor("default");
    }

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing]);

  if (collapsed) {
    return null;
  } else {
    return (
      <div
        style={{ width }}
        className={`h-full bg-vs-light-panel dark:bg-vs-dark-panel flex ${className}`}
        ref={panelRef}
      >
        <div className="h-full flex flex-col flex-grow">
          <div className="flex text-xs font-medium py-1.75 dark:text-vs-dark-text px-4 items-center">
            <span className="uppercase text-vs-light-default dark:text-vs-dark-default font-light">
              {title}
            </span>
            <Space />
            {settings && (
              <span className="hover:bg-vs-light-toolbar hover:dark:bg-vs-dark-toolbar p-1 rounded-md cursor-pointer">
                <Dropdown menu={settings}>
                  <VscEllipsis className="fill-vs-light-text dark:fill-vs-dark-text" />
                </Dropdown>
              </span>
            )}
          </div>
          <div className="flex-grow">{children}</div>
        </div>

        <span
          className={`h-full w-1 hover:bg-riders-green cursor-col-resize transition-all duration-300 ${
            isResizing ? "dark:bg-riders-green" : ""
          }`}
          onMouseDown={() => setIsResizing(true)}
        ></span>
      </div>
    );
  }
};

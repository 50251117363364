export const idGenerator = (length: number) => {
  const charArray = "abcdefghijklmnopqrstuvwxyz0123456789";

  const id = Array.from(
    { length },
    () => charArray[Math.floor(Math.random() * charArray.length)],
  ).join("");

  return id;
};

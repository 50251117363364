import {useEffect, useState} from "react";


const useLanguage = (project: any) => {
    const [language, setLanguage] = useState<string>("EN");

    useEffect(() => {
        if (project) {
            setLanguage(project?.editorLanguage || "EN");
        }
    }, [project]);

    return {
        language,
    };
}

export default useLanguage;

import React, { useEffect, useContext, useRef } from "react";
import { IScreen } from "../types";
import "../styles/style.css";
import { FileSystem, idGenerator, capitalize } from "../";
import { v4 as uuidv4 } from "uuid";
import { Auth } from "../../../contexts/ridersUserContext";
import {FormattedMessage} from "react-intl";

export const ScreenInfo = React.createContext({});

export const Screen: IScreen = ({ children, project, env }) => {
  const block = project.dev === "blockly";

  const [workspaces, setWorkspaces] = React.useState<any>([]);
  const [workspacelayout, setWorkspacelayout] = React.useState(
    `${
      project.workspaceDefaultType === "block" ? '"A A" "A A"' : '"A B" "A B"'
    }`
  );
  const [workspaceCounter, setWorkspaceCounter] = React.useState(
    project.workspaceDefaultType === "block" ? 1 : 2
  );

  const [isTerminalOpen, setIsTerminalOpen] = React.useState(false);

  const [blocklyXML, setBlocklyXML] = React.useState<string | undefined>(
    undefined
  );
  const [pythonCode, setPythonCode] = React.useState(undefined);
  const [editorFocus, setEditorFocus] = React.useState<any>(undefined);

  const [isDragging, setIsDragging] = React.useState(false);
  const [mousePosition, setMousePosition] = React.useState<any>(undefined);
  const [cursor, setCursor] = React.useState("default");
  const [refreshKey, setRefreshKey] = React.useState(uuidv4());

  const [customUserCode, setCustomUserCode] = React.useState<string>("");

  const { readFile, writeFile, updateFile, getBasePath, lastCommitHash } =
    useContext<any>(FileSystem.context);

  const { projectType, community, userCodeFilePath } = useContext<any>(Auth.context);

  const godotContainerRef = useRef<any>(null);
  const documentationRef = useRef<any>(null);

  // removeFileFromWorkspace
  const removeFileFromWorkspace = (workspaceId: string, tabId: string) => {
    const _workspaces = [...workspaces];
    const _workspace = _workspaces.find((w: any) => w.id === workspaceId);
    if (_workspace) {
      let _tabs = _workspace.tabs.filter((t: any) => t.id !== tabId);

      const lastIter = _tabs.length - 1;

      if (lastIter >= 0) {
        _tabs[lastIter].isActive = true;
        _workspace.tabs = _tabs;
        setWorkspaces(_workspaces);
      } else {
        removeWorkspace(workspaceId);
      }
    }
  };

  // addFileToWorkspace
  const addFileToWorkspace = (workspaceId: string, tab: any) => {
    const _workspaces = [...workspaces];
    let _workspace;

    if (workspaceId === "first") {
      _workspace = _workspaces[0];
    } else if (workspaceId === "last") {
      _workspace = _workspaces[_workspaces.length - 1];
    } else {
      _workspace = _workspaces.find((w: any) => w.id === workspaceId);
    }

    if (_workspace) {
      _workspace.tabs = _workspace.tabs.map((t: any) => {
        t.isActive = false;
        return t;
      });
      _workspace.tabs.push({
        ...tab,
        isSaved: true,
        isActive: true,
        id: idGenerator(6),
      });
    }
    setWorkspaces(_workspaces);
  };

  // changeFileInWorkspace
  const changeFileInWorkspace = (
    workspaceId: string,
    tabId: string,
    content: string
  ) => {
    const _workspaces = [...workspaces];
    const _workspace = _workspaces.find((w: any) => w.id === workspaceId);
    if (_workspace) {
      const _tab = _workspace.tabs.find((t: any) => t.id === tabId);
      if (_tab) {
        _tab.content = content;
      }
    }
    setWorkspaces(_workspaces);
  };

  // readWorkspaceFile
  const readWorkspaceFile = (workspaceId: string, tabId: string) => {
    const _workspace = workspaces.find((w: any) => w.id === workspaceId);
    if (_workspace) {
      const _tab = _workspace.tabs.find((t: any) => t.id === tabId);
      if (_tab) {
        return _tab.content;
      }
    }
    return "";
  };

  // setActiveInWorkspace
  const setActiveInWorkspace = (workspaceId: string, tabId: string) => {
    const _workspaces = [...workspaces];
    const _workspace = _workspaces.find((w: any) => w.id === workspaceId);

    if (_workspace) {
      let _tabs;
      if (tabId === "first") {
        _tabs = _workspace.tabs.map((t: any) => {
          t.isActive = false;
          return t;
        });
        _tabs[0].isActive = true;
        _workspace.tabs = _tabs;
      } else if (tabId === "last") {
        _tabs = _workspace.tabs.map((t: any) => {
          t.isActive = false;
          return t;
        });
        _tabs[_tabs.length - 1].isActive = true;
        _workspace.tabs = _tabs;
      } else {
        _tabs = _workspace.tabs.map((t: any) => {
          t.isActive = t.id === tabId;
          return t;
        });
      }
      _workspace.tabs = _tabs;
    }
    setWorkspaces(_workspaces);
  };

  // getActiveInWorkspace
  const getActiveInWorkspace = (workspaceId: string) => {
    const _workspace = workspaces.find((w: any) => w.id === workspaceId);
    if (_workspace) {
      const _tab = _workspace.tabs.find((t: any) => t.isActive);
      return _tab;
    }
    return null;
  };

  // const getWorkspaceFilePath = (workspaceId: string, tabId: string) => {
  //   const _workspace = workspaces.find((w: any) => w.id === workspaceId);
  //   if (_workspace) {
  //     const _tab = _workspace.tabs.find((t: any) => t.id === tabId);
  //     if (_tab) {
  //       return _tab.title;
  //     }
  //   }
  //   return "";
  // };

  // removeWorkspace
  const removeWorkspace = async (workspaceId: string) => {
    const _workspaces = workspaces.filter((w: any) => w.id !== workspaceId);
    if (workspaceCounter === 2) {
      setWorkspaces([..._workspaces]);
      setWorkspaceCounter(workspaceCounter - 1);
      setWorkspacelayout(`"A A" "A A"`);
    }
  };

  // tabRelocation
  const tabRelocation = (
    tabId: string,
    source: string,
    to: string,
    index: number
  ) => {
    const _workspaces = [...workspaces];
    const _sourceWorkspace = _workspaces.find((w: any) => w.id === source);
    const _toWorkspace = _workspaces.find((w: any) => w.id === to);
    if (_sourceWorkspace && _toWorkspace) {
      const _tab = _sourceWorkspace.tabs.find((t: any) => t.id === tabId);
      if (_tab) {
        _sourceWorkspace.tabs = _sourceWorkspace.tabs.filter(
          (t: any) => t.id !== tabId
        );
        _toWorkspace.tabs.splice(index, 0, _tab);
      }
    }

    setWorkspaces(_workspaces);
    setActiveInWorkspace(to, tabId);

    if (source !== to) {
      if (_sourceWorkspace.tabs.length === 0) {
        removeWorkspace(source);
      } else {
        if (getActiveInWorkspace(source) === undefined) {
          setActiveInWorkspace(source, "last");
        }
      }
    }
  };

  // get workspace and file id from title
  const getWorkspaceAndFileId = (title: string) => {
    let _workspaceId;
    let _fileId;
    workspaces.forEach((w: any) => {
      w.tabs.forEach((t: any) => {
        if (t.title === title) {
          _workspaceId = w.id;
          _fileId = t.id;
        }
      });
    });

    if (!_workspaceId || !_fileId) return null;
    return { workspaceId: _workspaceId, fileId: _fileId };
  };

  // add new workspace on drag and drop
  const addWorkspace = () => {
    const _workspaces = workspaces;
    if (workspaceCounter === 1) {
      const _workspace = {
        id: "B",
        tabs: [],
      };
      _workspaces.push(_workspace);
      setWorkspaces(_workspaces);
      setWorkspaceCounter(workspaceCounter + 1);
      if (mousePosition === "right") {
        setWorkspacelayout(`"A B" "A B"`);
      }
      if (mousePosition === "bottom") {
        setWorkspacelayout(`"A A" "B B"`);
      }
    } else {
      console.log("max workspaces reached");
    }
  };

  // listen and set mouse position
  const mouseMoveListener = (e: any) => {
    if (e.clientX / window.innerWidth > 0.85) {
      setMousePosition("right");
    } else if (e.clientY / window.innerHeight > 0.75) {
      setMousePosition("bottom");
    } else {
      setMousePosition(undefined);
    }
  };

  // add mousemove listener
  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", mouseMoveListener);
      return () => {
        window.removeEventListener("mousemove", mouseMoveListener);
        setMousePosition(undefined);
      };
    }
  }, [isDragging]);

  // prepare workspaces
  useEffect(() => {
    // setBlocklyXML
    document.title = `Riders | ${project.title}`;
    let documentationTitleKey = community === "deneyap" ? "documentationTabDeneyap" : "documentationTab" ;
    readFile("/blockly.xml")
      .then((_xml: string) => {
        let blocklyXML = _xml;
        setBlocklyXML(blocklyXML);        

        readFile(userCodeFilePath).then((usercode: string) => {
          if (project.workspaceDefaultType === "split") {
            const Atabs = [];
            if (project.dev === "code") {
              Atabs.push({
                id: idGenerator(6),
                title:
                  projectType === "robotics" ? "user_code.py" : "user_code.ino",
                path: userCodeFilePath,
                type: projectType === "robotics" ? "python" : "arduino",
                content: usercode,
                isSaved: true,
                isActive: false,
                closable: false,
              });
            } else if (project.dev === "blockly") {
              Atabs.push({
                id: idGenerator(6),
                title: <FormattedMessage id={"blockCodingTab"} />,
                path: "/blockly.xml",
                type: "blockly",
                content: blocklyXML, // Blockly xml sheet
                isSaved: true,
                isActive: false,
                closable: false,
              });
            }
            setWorkspaces([
              {
                id: "A",
                tabs: [
                  ...Atabs,
                  {
                    id: idGenerator(6),
                    title: <FormattedMessage id={documentationTitleKey} />,
                    type: "iframe",
                    url: project.documentationUrl,
                    isSaved: true,
                    isActive: true,
                    closable: false,
                  },
                ],
              },
              {
                id: "B",
                tabs: [
                  {
                    id: idGenerator(6),
                    title: capitalize(project.title!),
                    type: "simulation",
                    project: project.name,
                    isSaved: true,
                    isActive: true,
                    closable: false,
                  },
                ],
              },
            ]);
          } else if (project.workspaceDefaultType === "block") {
            const Atabs = [];
            if (project.dev === "code") {
              Atabs.push({
                id: idGenerator(6),
                title:
                  projectType === "robotics" ? "user_code.py" : "user_code.ino",
                path: userCodeFilePath,
                type: projectType === "robotics" ? "python" : "arduino",
                content: usercode,
                isSaved: true,
                isActive: false,
                closable: false,
              });
            } else if (project.dev === "blockly") {
              Atabs.push({
                id: idGenerator(6),
                title: <FormattedMessage id={"blockCodingTab"} />,
                type: "blockly",
                content: blocklyXML, // Blockly xml sheet
                path: "/blockly.xml",
                isSaved: true,
                isActive: false,
                closable: false,
              });
            }

            setWorkspaces([
              {
                id: "A",
                tabs: [
                  ...Atabs,
                  {
                    id: idGenerator(6),
                    title: <FormattedMessage id={documentationTitleKey} />,
                    type: "iframe",
                    url: project.documentationUrl,
                    isSaved: true,
                    isActive: true,
                    closable: false,
                  },
                  {
                    id: idGenerator(6),
                    title: capitalize(project.title!),
                    type: "simulation",
                    project: project.name,
                    isSaved: true,
                    isActive: false,
                    closable: false,
                  },
                ],
              },
            ]);
          }
        });
      })
      .catch((err: any) => {
        readFile(userCodeFilePath).then((usercode: string) => {
          if (project.workspaceDefaultType === "split") {
            const Atabs = [];
            if (project.dev === "code") {
              Atabs.push({
                id: idGenerator(6),
                title:
                  projectType === "robotics" ? "user_code.py" : "user_code.ino",
                path: userCodeFilePath,
                type: projectType === "robotics" ? "python" : "arduino",
                content: usercode,
                isSaved: true,
                isActive: false,
                closable: false,
              });
            } else if (project.dev === "blockly") {
              Atabs.push({
                id: idGenerator(6),
                title: <FormattedMessage id={"blockCodingTab"} />,
                path: "/blockly.xml",
                type: "blockly",
                content: blocklyXML, // Blockly xml sheet
                isSaved: true,
                isActive: false,
                closable: false,
              });
            }
            setWorkspaces([
              {
                id: "A",
                tabs: [
                  ...Atabs,
                  {
                    id: idGenerator(6),
                    title: <FormattedMessage id={documentationTitleKey} />,
                    type: "iframe",
                    url: project.documentationUrl,
                    isSaved: true,
                    isActive: true,
                    closable: false,
                  },
                ],
              },
              {
                id: "B",
                tabs: [
                  {
                    id: idGenerator(6),
                    title: capitalize(project.title!),
                    type: "simulation",
                    project: project.name,
                    isSaved: true,
                    isActive: true,
                    closable: false,
                  },
                ],
              },
            ]);
          } else if (project.workspaceDefaultType === "block") {
            const Atabs = [];
            if (project.dev === "code") {
              Atabs.push({
                id: idGenerator(6),
                title:
                  projectType === "robotics" ? "user_code.py" : "user_code.ino",
                path: userCodeFilePath,
                type: projectType === "robotics" ? "python" : "arduino",
                content: usercode,
                isSaved: true,
                isActive: false,
                closable: false,
              });
            } else if (project.dev === "blockly") {
              Atabs.push({
                id: idGenerator(6),
                title: <FormattedMessage id={"blockCodingTab"} />,
                type: "blockly",
                content: blocklyXML, // Blockly xml sheet
                path: "/blockly.xml",
                isSaved: true,
                isActive: false,
                closable: false,
              });
            }

            setWorkspaces([
              {
                id: "A",
                tabs: [
                  ...Atabs,
                  {
                    id: idGenerator(6),
                    title: <FormattedMessage id={documentationTitleKey} />,
                    type: "iframe",
                    url: project.documentationUrl,
                    isSaved: true,
                    isActive: true,
                    closable: false,
                  },
                  {
                    id: idGenerator(6),
                    title: capitalize(project.title!),
                    type: "simulation",
                    project: project.name,
                    isSaved: true,
                    isActive: false,
                    closable: false,
                  },
                ],
              },
            ]);
          }
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Save file on ctrl + s
  // useEffect(() => {
  //   const handleKeyDown = (event: any) => {
  //     if ((event.ctrlKey || event.metaKey) && event.key === "s") {
  //       event.preventDefault();
  //       if (editorFocus !== undefined) {
  //         const _workspace = workspaces.find(
  //           (w: any) => w.id === editorFocus.workspaceId
  //         );
  //         if (_workspace) {
  //           const _tab = _workspace.tabs.find(
  //             (t: any) => t.id === editorFocus.tabId
  //           );
  //           if (_tab) {
  //             if (_tab.type === "python" || _tab.type === "cpp") {
  //               writeFile(`${_tab.path}`, _tab.content);
  //               _tab.isSaved = true;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   };
  //   window.addEventListener("keydown", handleKeyDown);
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [editorFocus]);

  // // Compare file content with editor content
  // useEffect(() => {
  //   if (editorFocus !== undefined) {
  //     const interval = setInterval(() => {
  //       setWorkspaces((prev: any) => {
  //         const _workspaces = [...prev];
  //         _workspaces.forEach((w: any) => {
  //           w.tabs.forEach(async (t: any) => {
  //             if (t.type === "python" || t.type === "cpp") {
  //               if (t.content === (await readFile(`${t.path}`))) {
  //                 t.isSaved = true;
  //               } else {
  //                 t.isSaved = false;
  //               }
  //             }
  //           });
  //         });
  //         return _workspaces;
  //       });
  //     }, 1000);

  //     return () => clearInterval(interval);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [editorFocus]);

  // Save blockly xml to file
  useEffect(() => {
    if (blocklyXML === undefined) {
      return;
    }

    readFile("/blockly.xml").then((xml: string) => {
      if (xml === blocklyXML) {
        return;
      }
      updateFile(`/blockly.xml`, blocklyXML);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocklyXML]);

  // Save blockly python code to file
  useEffect(() => {
    if (block) {
      if (pythonCode === undefined) {
        return;
      }
      readFile(userCodeFilePath).then((code: string) => {
        if (code === pythonCode) {
          return;
        }
        updateFile(userCodeFilePath, pythonCode);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pythonCode]);

  const refreshFunction = () => {
    setRefreshKey(uuidv4());
  };

  return (
    <ScreenInfo.Provider
      value={{
        community,
        setCursor,
        workspacelayout,
        setWorkspacelayout,
        workspaceCounter,
        setWorkspaceCounter,
        isTerminalOpen,
        setIsTerminalOpen,
        workspaces,
        blocklyXML,
        setBlocklyXML,
        setPythonCode,
        pythonCode,
        removeFileFromWorkspace,
        addFileToWorkspace,
        changeFileInWorkspace,
        setActiveInWorkspace,
        setEditorFocus,
        readWorkspaceFile,
        getWorkspaceAndFileId,
        tabRelocation,
        setIsDragging,
        isDragging,
        mousePosition,
        addWorkspace,
        project,
        block,
        godotContainerRef,
        documentationRef,
        env,
        refreshKey,
        refreshFunction,
      }}
    >
      <div
        className={`w-screen h-screen overflow-x-hidden overflow-y-scroll scrollbar-hidden`}
        style={{ cursor }}
      >
        {children}
      </div>
    </ScreenInfo.Provider>
  );
};

Screen.context = ScreenInfo;

export const setCookie = (name: string, value: string, iat: number) => {
  const date = new Date();
  date.setTime(iat);
  const expires = "; expires=" + date.toUTCString();
  document.cookie =
    name + "=" + value + expires + "; path=/; SameSite=None; Secure;";
};

export const getCookie = (name: string) => {
  const nameEQ = name + "=";
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === " ")
      cookie = cookie.substring(1, cookie.length);
    if (cookie.indexOf(nameEQ) === 0)
      return cookie.substring(nameEQ.length, cookie.length);
  }
  return null;
};

export const deleteCookie = (name: string) => {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

export const hasCookie = (name: string) => {
  if (getCookie(name) === "undefined") {
    return false;
  } else if (getCookie(name) === null) {
    return false;
  } else if (getCookie(name) === "") {
    return false;
  } else if (typeof getCookie(name) === "undefined") {
    return false;
  } else {
    return true;
  }
};

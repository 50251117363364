import * as monaco from 'monaco-editor';


// KEYWORD SETTINGS

const keywords =  ["abstract", "amp", "array", "auto", "bool", "break", "case", "catch", "char", "class", "const", "constexpr", 
           "const_cast", "continue", "cpu", "decltype", "default", "delegate", "delete", "do", "double", "dynamic_cast", 
           "each", "else", "enum", "event", "explicit", "export", "extern", "false", "final", "finally", "float", "for", 
           "friend", "gcnew", "generic", "goto", "if", "in", "initonly", "inline", "int", "interface", "interior_ptr", 
           "internal", "literal", "long", "mutable", "namespace", "new", "noexcept", "nullptr", "__nullptr", "operator", 
           "override", "partial", "pascal", "pin_ptr", "private", "property", "protected", "public", "ref", "register", 
           "reinterpret_cast", "restrict", "return", "safe_cast", "sealed", "short", "signed", "sizeof", "static", 
           "static_assert", "static_cast", "struct", "switch", "template", "this", "thread_local", "throw", "tile_static", 
           "true", "try", "typedef", "typeid", "typename", "union", "unsigned", "using", "virtual", "void", "volatile", 
           "wchar_t", "where", "while", "_asm", "_based", "_cdecl", "_declspec", "_fastcall", "_if_exists", "_if_not_exists",
            "_inline", "_multiple_inheritance", "_pascal", "_single_inheritance", "_stdcall", "_virtual_inheritance", "_w64", 
            "__abstract", "__alignof", "__asm", "__assume", "__based", "__box", "__builtin_alignof", "__cdecl", "__clrcall", 
            "__declspec", "__delegate", "__event", "__except", "__fastcall", "__finally", "__forceinline", "__gc", 
            "__hook", "__identifier", "__if_exists", "__if_not_exists", "__inline", "__int128", "__int16", "__int32", 
            "__int64", "__int8", "__interface", "__leave", "__m128", "__m128d", "__m128i", "__m256", "__m256d", "__m256i", 
            "__m64", "__multiple_inheritance", "__newslot", "__nogc", "__noop", "__nounwind", "__novtordisp", "__pascal", 
            "__pin", "__pragma", "__property", "__ptr32", "__ptr64", "__raise", "__restrict", "__resume", "__sealed", 
            "__single_inheritance", "__stdcall", "__super", "__thiscall", "__try", "__try_cast", "__typeof", "__unaligned", 
            "__unhook", "__uuidof", "__value", "__virtual_inheritance", "__w64", "__wchar_t"];

const keywords2 = ["array", "bool", "boolean", "byte", "char", "double", "float", "int", "long", "short", "size_t", "String", 
     "string", "unsigned char", "unsigned int", "unsigned long", "void", "word", "int8_t", "int16_t", "int32_t", 
     "int64_t", "uint8_t", "uint16_t", "uint32_t", "uint64_t"];

const avrConstants = ["PORTB", "DDRB", "PINB", "PORTC", "DDRC", "PINC", "PORTD", "DDRD", "PIND", "TCCR0A", "TCCR0B", "TCNT0", 
     "TIFR0", "TIMSK0", "OCR0A", "OCR0B", "ICR1", "TCCR1A", "TCCR1B", "TCCR1C", "TIFR1", "TCNT1", "TCNT1A", "TCNT1B", 
     "TIMSK1", "OCR1A", "OCR1B", "TCCR2A", "TCCR2B", "TCNT2", "TIFR2", "TIMSK2", "OCR2A", "OCR2B"];

const avrConstants2 = ["CS00", "CS01", "CS02", "TOV0", "CS10", "CS11", "CS12", "TOV1", "CS20", "CS21", "CS22", "TOV2"];

const keywordsList = [...keywords, ...keywords2];

const avrConstantsList = [...avrConstants, ...avrConstants2];

const arduinoFunctionsList = ["abs", "acos", "asin", "atan", "atan2", "ceil", "constrain", "cos", "degrees", "exp", "floor", "log", "map", 
     "max", "min", "radians", "random", "randomSeed", "round", "sin", "sq", "sqrt", "tan", "bitRead", "bitWrite", 
     "bitSet", "bitClear", "bit", "highByte", "lowByte", "analogReference", "analogRead", "analogWrite", 
     "analogReadResolution", "analogWriteResolution", "attachInterrupt", "detachInterrupt", "delay", "delayMicroseconds", 
     "digitalPinToInterrupt", "digitalWrite", "digitalRead", "interrupts", "millis", "micros", "noInterrupts", "noTone", 
     "pinMode", "pulseIn", "shiftIn", "shiftOut", "tone", "begin", "end", "read", "readBytes", "readBytesUntil", "write", 
     "print", "println", "parseInt", "parseFloat", "peek", "find", "findUntil", "readString", "readStringUntil", 
                              "setTimeout", "available", "availableForWrite", "flush", "put", "get", "read", "write", "update"] ; 

const arduinoConstsList = ["A0", "A1", "A2", "A3", "A4", "A5", "HIGH", "LOW", "INPUT", "OUTPUT", "INPUT_PULLUP", "LED_BUILTIN", "DEC", "BIN", "HEX",
                           "OCT", "BYTE", "PI", "HALF_PI", "TWO_PI", "LSBFIRST", "MSBFIRST", "CHANGE", "FALLING", "RISING", "DEFAULT", "EXTERNAL", 
                           "INTERNAL", "INTERNAL1V1", "INTERNAL2V56", "_SFR_IO_ADDR"];

const arduinoClassesList= ["Serial", "Wire", "Stream", "EEPROM"];


// LANGUAGE TOKEN PROVIDER SETTINGS
const cppLangCopySettings: monaco.languages.IMonarchLanguage = {
    defaultToken: "",
    brackets: [{
        token: "delimiter.curly",
        open: "{",
        close: "}",
    }, {
        token: "delimiter.parenthesis",
        open: "(",
        close: ")"
    }, {
        token: "delimiter.square",
        open: "[",
        close: "]"
    }, {
        token: "delimiter.angle",
        open: "<",
        close: ">"
    }],
    operators: ["=", ">", "<", "!", "~", "?", ":", "==", "<=", ">=", "!=", "&&", "||", "++", "--", "+", "-", "*", "/", "&", "|", "^", "%", "<<", ">>", ">>>", "+=", "-=", "*=", "/=", "&=", "|=", "^=", "%=", "<<=", ">>=", ">>>="],
    symbols: /[=><!~?:&|+\-*\/\^%]+/,
    escapes: /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,
    integersuffix: /(ll|LL|u|U|l|L)?(ll|LL|u|U|l|L)?/,
    floatsuffix: /[fFlL]?/,
    encoding: /u|u8|U|L/,
    tokenizer: {
        root: [[/@encoding?R\"(?:([^ ()\\\t]*))\(/, {
            token: "string.raw.begin",
            next: "@raw.$1"
        }], [/[a-zA-Z_]\w*/, {
            cases: {
                "@keywords": {
                    token: "keyword.$0"
                },
                "@default": "identifier"
            }
        }], {
            include: "@whitespace"
        }, [/\[\[.*\]\]/, "annotation"], [/^\s*#include/, {
            token: "keyword.directive.include",
            next: "@include"
        }], [/^\s*#\s*\w+/, "keyword"], [/[{}()\[\]]/, "@brackets"], [/[<>](?!@symbols)/, "@brackets"], [/@symbols/, {
            cases: {
                "@operators": "delimiter",
                "@default": ""
            }
        }], [/\d*\d+[eE]([\-+]?\d+)?(@floatsuffix)/, "number.float"], [/\d*\.\d+([eE][\-+]?\d+)?(@floatsuffix)/, "number.float"], [/0[xX][0-9a-fA-F']*[0-9a-fA-F](@integersuffix)/, "number.hex"], [/0[0-7']*[0-7](@integersuffix)/, "number.octal"], [/0[bB][0-1']*[0-1](@integersuffix)/, "number.binary"], [/\d[\d']*\d(@integersuffix)/, "number"], [/\d(@integersuffix)/, "number"], [/[;,.]/, "delimiter"], [/"([^"\\]|\\.)*$/, "string.invalid"], [/"/, "string", "@string"], [/'[^\\']'/, "string"], [/(')(@escapes)(')/, ["string", "string.escape", "string"]], [/'/, "string.invalid"]],
        whitespace: [
            [/[ \t\r\n]+/, ""], [/\/\*\*(?!\/)/, "comment.doc", "@doccomment"], 
            [/\/\*/, "comment", "@comment"], [/\/\/.*$/, "comment"]
        ],
        comment: [[/[^\/*]+/, "comment"], [/\*\//, "comment", "@pop"], [/[\/*]/, "comment"]],
        doccomment: [[/[^\/*]+/, "comment.doc"], [/\*\//, "comment.doc", "@pop"], [/[\/*]/, "comment.doc"]],
        string: [[/[^\\"]+/, "string"], [/@escapes/, "string.escape"], [/\\./, "string.escape.invalid"], [/"/, "string", "@pop"]],
        raw: [[/(.*)(\))(?:([^ ()\\\t"]*))(\")/, {
            cases: {
                "$3==$S2": ["string.raw", "string.raw.end", "string.raw.end", {
                    token: "string.raw.end",
                    next: "@pop"
                }],
                "@default": ["string.raw", "string.raw", "string.raw", "string.raw"]
            }
        }], [/.*/, "string.raw"]],
        
        include: [
            // @ts-ignore
            [/(\s*)(<)([^<>]*)(>)/, ["", "keyword.directive.include.begin", "string.include.identifier", {token: "keyword.directive.include.end",next: "@pop"}]], 
            // @ts-ignore      
            [/(\s*)(")([^"]*)(")/, ["", "keyword.directive.include.begin", "string.include.identifier",{token: "keyword.directive.include.end",next: "@pop"}]]],
    }
}



const arduinoLangSettings: monaco.languages.IMonarchLanguage = {
    ...cppLangCopySettings,
    tokenPostfix: ".ino",
    keywords: [...keywordsList],
    arduinoFunctions: [...arduinoFunctionsList],
    arduinoConsts: [...arduinoConstsList],
    arduinoClasses: [...arduinoClassesList],
    avrConsts: [...avrConstantsList],
    tokenizer: {
        ...cppLangCopySettings.tokenizer,
        root: cppLangCopySettings.tokenizer.root.map((e: any) =>e[1] && e[1].cases && e[1].cases["@keywords"] ? [/[a-zA-Z_]\w*/, {
            cases: {
                "@keywords": {
                    token: "keyword.$0"
                },
                "@arduinoFunctions": "arduino-function",
                "@arduinoConsts": "arduino-const",
                "@avrConsts": "arduino-avr-const",
                "@arduinoClasses": "arduino-class",
                "setup|loop": "arduino-reserved",
                "@default": "identifier"
            }
        }] : e)
    }
};

// LANGUAGE CONFIGURATION
const languageConfiguration = {
    comments: {
        lineComment: "//",
        blockComment: ["/*", "*/"]
    },
    brackets: [["{", "}"], ["[", "]"], ["(", ")"]],
    autoClosingPairs: [{
        open: "[",
        close: "]"
    }, {
        open: "{",
        close: "}"
    }, {
        open: "(",
        close: ")"
    }, {
        open: "'",
        close: "'",
        notIn: ["string", "comment"]
    }, {
        open: '"',
        close: '"',
        notIn: ["string"]
    }],
    surroundingPairs: [{
        open: "{",
        close: "}"
    }, {
        open: "[",
        close: "]"
    }, {
        open: "(",
        close: ")"
    }, {
        open: '"',
        close: '"'
    }, {
        open: "'",
        close: "'"
    }],
    folding: {
        markers: {
            start: RegExp("^\\s*#pragma\\s+region\\b"),
            end: RegExp("^\\s*#pragma\\s+endregion\\b")
        }
    }
};

export const setArduinoLanguage = (monaco: any) => {
    monaco.languages.register({id: "arduino"});
    monaco.languages.setLanguageConfiguration("arduino", languageConfiguration);
    monaco.languages.setMonarchTokensProvider("arduino", arduinoLangSettings);
    // THEME SETTINGS
    monaco.editor.defineTheme("arduino-theme", {
        base: "vs",
        inherit: !0,
        rules: [{
            token: "arduino-class",
            foreground: "E97366",
            fontStyle: "bold"
        }, {
            token: "arduino-function",
            foreground: "E97366"
        }, {
            token: "arduino-const",
            foreground: "00979C"
        }, {
            token: "arduino-avr-const",
            foreground: "97009C"
        }, {
            token: "arduino-reserved",
            foreground: "5E6D03"
        }, {
            token: "comment",
            foreground: "727C81"
        },
        {
            token: "delimiter",
            foreground: "0000FF"
        }
    ],
        colors: {}
    });
};

import React from "react";
import { ISvgProps } from "../../types";

export const RidersIcon: React.FC<ISvgProps> = ({
  color,
  width,
  height,
  className,
  fill,
}) => {
  return (
    <svg
      width={width || "290"}
      height={height || "346"}
      viewBox="0 0 290 346"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M288.1 216.7L213.1 8.99999C212.2 6.39999 210 4.59999 207.3 3.99999C113.9 -14.1 23.3 47 5.10003 140.3C-13 233.6 48.1 324.3 141.4 342.5C152.3 344.6 163.5 345.7 174.5 345.7C175.6 345.7 176.7 345.7 177.9 345.7C180.2 345.7 182.4 344.5 183.9 342.7C185.3 340.8 185.8 338.4 185.3 336.2L171 277.4C174.8 274.3 177.2 269.6 177.2 264.5C177.2 255.3 169.5 247.9 160.1 247.9C150.4 247.9 143.1 255.3 143.1 264.8C143.1 272.8 148.4 279.2 156 280.9C156 281 156 281 156 281.1L168 330.3C160.1 330 152.3 329 144.5 327.5C59.5 311 3.80001 228.4 20.4 143.4C36.6 59.9 116.7 4.59999 200.2 18.5L271.6 216.2L239.7 236.6C227.8 224.4 194.8 192.7 166.3 185.6C153 182.3 144.8 171.6 140.3 163.1C137.1 157.1 135.1 151.2 133.9 146.3C138.7 143.3 141.9 138 141.9 132.1C141.9 122.9 134.2 115.5 124.8 115.5C115.1 115.5 107.8 122.9 107.8 132.4C107.8 139.6 112.1 145.4 118.4 147.8C119.7 154 121.9 160.4 124.4 166C132.9 184.3 146.4 196.6 162.5 200.6C192.7 208.1 232.3 251.3 232.7 251.7C235.2 254.5 239.4 255 242.6 253L285.1 225.9C288 223.9 289.3 220.1 288.1 216.7Z"
        fill={color || "url(#paint0_linear)"}
        className={fill}
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="1.93873"
          y1="173.353"
          x2="288.516"
          y2="173.353"
          gradientUnits="userSpaceOnUse"
        ></linearGradient>
      </defs>
    </svg>
  );
};

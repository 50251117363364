import { FC } from "react";
import { RidersIcon } from "../components/shared";
import { IParentProps } from "../types";

const errorPage: FC<{ community: string } & IParentProps> = ({
  children,
  community,
}) => {
  return (
    <div className="flex-grow w-full flex flex-col items-center justify-center space-x-4">
      {community === "edusim" ? (
        <img src="/edusim_md.png" alt="logo" width="200" className="mb-8" />
      ) : (
        <RidersIcon
          width={200}
          fill={`fill-vs-light-panel fill-riders-green`}
        />
      )}
      <div className="flex flex-col space-x-2 text-slate-300 items-center">
        {children}
      </div>
    </div>
  );
};

export default errorPage;

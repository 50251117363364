import React from "react";
import { IButtonProps } from "../types";

export const Button: React.FC<IButtonProps> = ({
  children,
  type = "primary",
  onClick,
  block = false,
  disabled = false,
}) => {
  return (
    <button
      className={`${type === "primary" ? "bg-riders-green" : ""} ${
        block ? "w-full" : ""
      } py-0 px-4 flex justify-center font-medium rounded-sm transition-all text-sm ${
        disabled
          ? "opacity-50 cursor-not-allowed"
          : "cursor-pointer hover:bg-riders-green-dark"
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      <span>{children}</span>
    </button>
  );
};

import React, { useState, useEffect, useRef, useContext } from "react";
import { idGenerator, Screen } from "./";
import { FaTimes } from "react-icons/fa";
import { VscTerminal } from "react-icons/vsc";
import { Auth } from "../../contexts/ridersUserContext";

export const Terminal: React.FC<{
  className?: string;
  style?: React.CSSProperties;
}> = ({ className, style }) => {
  const [output, setOutput] = useState<any>([{ id: "", msg: "" }]);

  const { setIsTerminalOpen, isTerminalOpen } = useContext<any>(Screen.context);
  const { user } = useContext<any>(Auth.context);

  const myRef = useRef<any>(null);

  const action = (type: string, msg?: string) => {
    if (type === "clearTerminal") {
      setOutput([{ id: "", msg: "" }]);
      return;
    }
    if (type === "openTerminal") {
      setIsTerminalOpen(true);
      return;
    }

    if (type === "closeTerminal") {
      setIsTerminalOpen(false);
      return;
    }

    if (type === "collapseTerminal") {
      setIsTerminalOpen(!isTerminalOpen);
      return;
    }

    if (type === "error") {
      const id = idGenerator(8);
      let newMsgs = msg ? msg.split("\n") : [];
      const msgArr = newMsgs.map((m) => {
        return {
          id: id,
          msg: m,
          class: "text-red",
        };
      });
      setOutput([...output, ...msgArr]);
    }

    if (type === "log") {
      const id = idGenerator(8);
      let newMsgs = msg ? msg.split("\n") : [];
      const msgArr = newMsgs.map((m) => {
        return {
          id: id,
          msg: m,
          class: "text-gray-300",
        };
      });
      setOutput([...output, ...msgArr]);
    }
  };

  window.setTerminalAction = action;

  useEffect(() => {
    myRef.current.scrollTop = myRef.current?.scrollHeight;
  }, [output]);

  return (
    <div
      className={`w-full bg-vs-dark-background border-t border-vs-dark-border px-4 pb-2 flex flex-col space-y-2 ${className}`}
      style={style}
    >
      <div className="flex justify-between">
        <div className="flex">
          <span
            className="py-1 px-1 border-b text-xs cursor-pointer"
            onClick={() => action("clearTerminal")}
          >
            TERMINAL
          </span>
        </div>
        <div className="flex justify-end items-center space-x-2">
          {/* <span
            className="font-bold cursor-pointer"
            onClick={() =>
              log("test 1\ntest 2\n<p style='color:red'>test 3</p>", "err")
            }
          >
            Err
          </span>
          <span
            className="font-bold cursor-pointer"
            onClick={() =>
              log("test 1\ntest 2\n<p style='color:red'>test 3</p>")
            }
          >
            new
          </span> */}
          <span
            className="flex space-x-2 items-center hover:bg-vs-dark-border py-1 px-1.5 rounded cursor-pointer"
            onClick={() =>
              action("error", "test 1\ntest 2\n<p style='color:red'>test 3</p>")
            }
          >
            <VscTerminal />
            <span className=" rext-xs">python</span>
          </span>
          <span
            className="font-extralight cursor-pointer hover:bg-vs-dark-border p-1 rounded"
            onClick={() => setIsTerminalOpen(false)}
          >
            <FaTimes />
          </span>
        </div>
      </div>
      <div
        className="fit flex flex-col text-xs overflow-y-scroll -space-y-1.5 scrollbar"
        style={{ minHeight: "150px" }}
        ref={myRef}
      >
        {output.map((e: any, i: number) => {
          const newLog = i === 0 || e.id !== output[i - 1].id;
          return (
            <span className={e.class} key={i}>
              {newLog ? (
                <span className="text-white font-bold">
                  {user.username}@Riders %{" "}
                </span>
              ) : (
                ""
              )}
              {`${e.msg}`}
            </span>
          );
        })}
      </div>
    </div>
  );
};

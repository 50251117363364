import React from "react";

export const ToolbarIcon: React.FC<any> = ({
  Icon,
  size = "text-2xl",
  className,
  active = false,
  height = "h-8 dark:h-12",
  activeColor = "fill-vs-light-input dark:fill-vs-dark-input",
  activeBorderColor = "border-vs-light-input dark:border-vs-dark-input",
  alert = 0,
  onClick: handler,
}) => {
  return (
    <span
      className={`w-8 dark:w-12 my-1 dark:my-0 cursor-pointer box-border flex items-center justify-center relative rounded-full dark:rounded-none bg-arduino-green-light dark:bg-vs-dark-toolbar ${
        active ? "border-l-2 " + activeBorderColor : ""
      } ${height + " " + className}`}
      onClick={handler}
    >
      <Icon
        className={`${
          active
            ? `${activeColor}`
            : "fill-arduino-green-dark dark:fill-vs-dark-disabled"
        } ${size}`}
        fill={
          active
            ? `${activeColor}`
            : "fill-arduino-green-dark dark:fill-vs-dark-disabled"
        }
      />
      {alert > 0 && (
        <span className="absolute bottom-1.5 right-1.5 text-2xs bg-riders-green rounded-full px-1">
          {alert}
        </span>
      )}
    </span>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { getCookie, setCookie, hasCookie } from "../components/shared/Cookie";
import { useParams } from "react-router-dom";
import moment from "moment";

import { IScreen } from "../components/vs-code/types";
import { idleShutDown, refreshToken, verifyToken } from "../api";
export const AuthInfo = React.createContext({});

export const Auth: IScreen = ({ children }) => {
  const params = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const url = new URL(window.location.href);

  const communityValue = queryParams.has("community")
    ? queryParams.get("community")
    : url.origin.includes("deneyap")
    ? "deneyap"
    : "riders";

  const [user, setUser] = useState<any>(null);
  const [project, setProject] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState("editor");
  const [community, setCommunity] = useState<string>(communityValue!);
  const [shutDown, setShutDown] = useState<any>(null);
  const [accessTokenKey, setAccessTokenKey] = useState<string>(
    params.token?.slice(-8) || "accessToken"
  );
  const [lastActivity, setLastActivity] = useState<number>(moment().unix());
  const [isIdle, setIsIdle] = useState<boolean>(false);
  const [language, setLanguage] = useState<string | undefined>();
  const [userCodeFilePath, setUserCodeFilePath] = useState<string>("");

  const [projectType, setProjectType] = useState<string>("robotics");

  const getToken = () => {
    return getCookie(accessTokenKey);
  };

  const getProject = () => {
    return project;
  };

  const expireHandler = () => {
    setCookie(accessTokenKey, "", new Date().getTime() - 1000);
    setCookie(`refreshToken`, "", new Date().getTime() - 1000);
    if (community === "riders") {
      setShutDown("expired");
    } else if (community === "deneyap") {
      return;
    } else {
      setUser(null);
    }
  };

  useEffect(() => {
    console.log("language", language);
    if (["cpp", "ino"].includes(language!)) {
      setProjectType("electronics");
      setUserCodeFilePath("usercode/user_code.ino");
      document.getElementsByTagName("html")[0].classList.remove("dark");
    }

    if (language === "python") {
      setUserCodeFilePath("usercode/user_code.py");
    }
  }, [language]);

  useEffect(() => {
    const apiToken = getCookie(accessTokenKey) || params.token!;

    verifyToken(apiToken)
      .then((data) => {
        const {
          status,
          user,
          accessToken,
          git_address,
          git_branch_name,
          is_preview,
          session_id,
          active_sessions,
          project_id,
          project,
          files,
          storage,
          storage_address,
        } = data;
        if (status === "verified") {
          setLanguage(project.language ? project.language : "python");
          setCookie(
            accessTokenKey,
            accessToken,
            new Date().getTime() + 1000 * 60 * 3
          );
          setUser(user);
          setProject({
            ...project,
            project_id: project_id,
            git_address: git_address,
            git_branch: git_branch_name,
            session_id: session_id || "23",
            activeSessionList: active_sessions || [],
            storage_address: storage_address,
            storage: storage,
            files: files,
          });
          setMode(is_preview ? "preview" : "editor");
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    //interval to refresh token
    const interval = setInterval(() => {
      if (hasCookie(accessTokenKey)) {
        const accessToken = getCookie(accessTokenKey)!;
        refreshToken(accessToken)
          .then((data) => {
            const { status, accessToken } = data;
            if (status === "verified") {
              setCookie(
                accessTokenKey,
                accessToken,
                new Date().getTime() + 1000 * 60 * 3
              );
            } else {
              expireHandler();
            }
          })
          .catch((err) => {
            expireHandler();
          });
      } else {
        expireHandler();
      }
    }, 1000 * 120);
  }, []);

  useEffect(() => {
    const inactivityCheck = setInterval(async () => {
      if (moment().unix() - lastActivity > 60 * 20 && community === "riders") {
        const accessToken = getCookie(accessTokenKey);
        setIsIdle(true);
        if (accessToken) {
          await idleShutDown(accessToken);
          expireHandler();
        }
      }
    }, 1000 * 57);

    return () => {
      clearInterval(inactivityCheck);
    };
  }, [lastActivity]);

  const handleActivity = useCallback(() => {
    setLastActivity(moment().unix());
  }, []);

  useEffect(() => {
    if (!isIdle) {
      window.addEventListener("keydown", handleActivity);
      window.addEventListener("click", handleActivity);
    } else {
      console.log("idle");
    }

    return () => {
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("click", handleActivity);
    };
  }, [lastActivity]);

  useEffect(() => {
    if (shutDown) {
      const coverElement = document.getElementById("cover-screen-expires");

      // Eğer cover elementi DOM'dan kaldırılırsa bu fonksiyon çağrılacak
      const onCoverRemoved = () => {
        const htmlElement = document.documentElement;
        if (htmlElement) {
          htmlElement.remove();
        }
      };

      // MutationObserver ile cover elementinin DOM'dan kaldırılıp kaldırılmadığını takip edin
      const observer = new MutationObserver((mutationsList: any) => {
        for (const mutation of mutationsList) {
          if (mutation.type === "childList") {
            for (const removedNode of mutation.removedNodes) {
              if (removedNode === coverElement) {
                onCoverRemoved();
                observer.disconnect(); // Observer'ı durdurun, çünkü artık gerekli değil
                break;
              }
            }
          }
        }
      });

      // cover elementinin ebeveynini gözlemlemeye başlayın
      observer.observe(coverElement?.parentElement as any, { childList: true });
    }
  }, [user]);

  return (
    <AuthInfo.Provider
      value={{
        user,
        project,
        mode,
        loading,
        setLoading,
        community,
        shutDown,
        setShutDown,
        accessTokenKey,
        language,
        setLanguage,
        getToken,
        getProject,
        projectType,
        setProjectType,
        userCodeFilePath,
        setUserCodeFilePath,
      }}
    >
      {children}
    </AuthInfo.Provider>
  );
};

Auth.context = AuthInfo;

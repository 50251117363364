export const isInTimeCheck = (time: string, duration: number): boolean => {
  const [datePart, timePart] = time.split(":");
  const [year, month, day] = datePart.split("-").map(Number);
  const [hour, minute] = timePart.split("-").map(Number);

  const inputTime = new Date(year, month - 1, day, hour, minute);
  const currentTime = new Date();
  const elapsedTime = currentTime.getTime() - inputTime.getTime();

  return elapsedTime <= duration;
};

export const isInTimeMs = (time: number, duration: number): boolean => {
  const inputTime = new Date(time);
  const currentTime = new Date();
  const elapsedTime = currentTime.getTime() - inputTime.getTime();

  return elapsedTime <= duration;
};

import React, { useState, useEffect, useContext } from "react";
import { ILayoutProps } from "../../types";
import ErrorPage from "../../pages/errorPage";
import EditorLayout from "./EditorLayout";
import { Screen, FileSystem } from "../vs-code";
import { Auth } from "../../contexts/ridersUserContext";
import { Communication } from "../../contexts/communicationContext";

const Layout: React.FC<ILayoutProps> = ({ children }) => {
  const { user, loading, project, community } = useContext<any>(Auth.context);

  useEffect(() => {
    if (project === undefined) {
      document.getElementById("loading")?.remove();
    } else if (!loading) {
      document.getElementById("loading")?.remove();
    }
  }, [project, loading]);

  return (
    <div className="h-screen w-screen flex items-center justify-center bg-arduino-green-dark dark:bg-vs-dark-background text-vs-dark-panel dark:text-white">
      {!user ? (
        <ErrorPage community={community}>
          <div>
            403 Forbidden |{" "}
            <a
              href={
                community === "edusim"
                  ? "https://simulearn.eba.gov.tr"
                  : "https://riders.ai"
              }
              className="bg-vs-dark-toolbar rounded-md px-2 py-1"
            >
              Click Here To Authorize
            </a>
          </div>
        </ErrorPage>
      ) : !project ? (
        <ErrorPage community={community}>
          <div>
            {community === "edusim" ? "Edusim" : "Riders"} Can't Find Project |{" "}
            <a
              href={
                community === "edusim"
                  ? "https://simulearn.eba.gov.tr"
                  : "https://riders.ai"
              }
              className="bg-arduino-green-dark dark:bg-vs-dark-toolbar rounded-md px-2 py-1"
            >
              Click Here To Explore
            </a>
          </div>
        </ErrorPage>
      ) : (
        <FileSystem project={project}>
          {!loading && (
            <Screen project={project} env={community}>
              <Communication>
                <EditorLayout />
              </Communication>
            </Screen>
          )}
        </FileSystem>
      )}
    </div>
  );
};
export default Layout;

import axios from "axios";

export const requestWithMaxReties: any = async (config: any, retries = 3) => {
  try {
    const resp = await axios(config);
    return resp;
  } catch (error) {
    console.log(error);
    if (retries === 0) {
      throw error;
    }
    // Zaman aşımını bekleyin ve sonucu döndürün
    await new Promise((resolve) => setTimeout(resolve, 1000 * 20));
    return requestWithMaxReties(config, retries - 1);
  }
};

import Blockly from "blockly/core";

/**
 * Arduino code generator.
 * @type !Blockly.Generator
 */
const ArduinoBlockly: any = new Blockly.Generator("Arduino");

/**
 * List of illegal variable names.
 * This is not intended to be a security feature.  Blockly is 100% client-side,
 * so bypassing this list is trivial.  This is intended to prevent users from
 * accidentally clobbering a built-in object or function.
 * @private
 */
ArduinoBlockly.addReservedWords(
  // http://arduino.cc/en/Reference/HomePage
  "setup,loop,if,else,for,switch,case,while,do,break,continue,return,goto,define,include,HIGH,LOW,INPUT,OUTPUT,INPUT_PULLUP,true,false,interger, constants,floating,point,void,bookean,char,unsigned,byte,int,word,long,float,double,string,String,array,static, volatile,const,sizeof,pinMode,digitalWrite,digitalRead,analogReference,analogRead,analogWrite,tone,noTone,shiftOut,shitIn,pulseIn,millis,micros,delay,delayMicroseconds,min,max,abs,constrain,map,pow,sqrt,sin,cos,tan,randomSeed,random,lowByte,highByte,bitRead,bitWrite,bitSet,bitClear,bit,attachInterrupt,detachInterrupt,interrupts,noInterrupts"
);

/**
 * Order of operation ENUMs.
 *
 */
ArduinoBlockly.ORDER_ATOMIC = 0; // 0 "" ...
ArduinoBlockly.ORDER_UNARY_POSTFIX = 1; // expr++ expr-- () [] .
ArduinoBlockly.ORDER_UNARY_PREFIX = 2; // -expr !expr ~expr ++expr --expr
ArduinoBlockly.ORDER_FUNCTION_CALL = 2; // () [];
ArduinoBlockly.ORDER_MODULUS = 3; // %;
ArduinoBlockly.ORDER_MULTIPLICATIVE = 3; // * / % ~/
ArduinoBlockly.ORDER_DIVISION = 3; // /
ArduinoBlockly.ORDER_MULTIPLICATION = 3; // *
ArduinoBlockly.ORDER_ADDITIVE = 4; // + -
ArduinoBlockly.ORDER_SUBTRACTION = 4; // -
ArduinoBlockly.ORDER_ADDITION = 4; // +
ArduinoBlockly.ORDER_SHIFT = 5; // << >>
ArduinoBlockly.ORDER_RELATIONAL = 6; // is is! >= > <= <
ArduinoBlockly.ORDER_EQUALITY = 7; // == != === !==
ArduinoBlockly.ORDER_BITWISE_AND = 8; // &
ArduinoBlockly.ORDER_BITWISE_XOR = 9; // ^
ArduinoBlockly.ORDER_BITWISE_OR = 10; // |
ArduinoBlockly.ORDER_LOGICAL_AND = 11; // &&
ArduinoBlockly.ORDER_LOGICAL_OR = 12; // ||
ArduinoBlockly.ORDER_CONDITIONAL = 13; // expr ? expr : expr
ArduinoBlockly.ORDER_ASSIGNMENT = 14; // = *= /= ~/= %= += -= <<= >>= &= ^= |=
ArduinoBlockly.ORDER_COMMA = 15; // ,
ArduinoBlockly.ORDER_UNARY_NEGATION = 16;
ArduinoBlockly.ORDER_MEMBER = 17;
ArduinoBlockly.ORDER_NONE = 99; // (...)

/**
 * List of outer-inner pairings that do NOT require parentheses.
 * @type {!Array.<!Array.<number>>}
 */
ArduinoBlockly.ORDER_OVERRIDES = [
  // (foo()).bar -> foo().bar
  // (foo())[0] -> foo()[0]
  [ArduinoBlockly.ORDER_FUNCTION_CALL, ArduinoBlockly.ORDER_MEMBER],
  // (foo())() -> foo()()
  [ArduinoBlockly.ORDER_FUNCTION_CALL, ArduinoBlockly.ORDER_FUNCTION_CALL],
  // (foo.bar).baz -> foo.bar.baz
  // (foo.bar)[0] -> foo.bar[0]
  // (foo[0]).bar -> foo[0].bar
  // (foo[0])[1] -> foo[0][1]
  [ArduinoBlockly.ORDER_MEMBER, ArduinoBlockly.ORDER_MEMBER],
  // (foo.bar)() -> foo.bar()
  // (foo[0])() -> foo[0]()
  [ArduinoBlockly.ORDER_MEMBER, ArduinoBlockly.ORDER_FUNCTION_CALL],
  // !(!foo) -> !!foo
  [ArduinoBlockly.ORDER_LOGICAL_NOT, ArduinoBlockly.ORDER_LOGICAL_NOT],
  // a * (b * c) -> a * b * c
  [ArduinoBlockly.ORDER_MULTIPLICATION, ArduinoBlockly.ORDER_MULTIPLICATION],
  // a + (b + c) -> a + b + c
  [ArduinoBlockly.ORDER_ADDITION, ArduinoBlockly.ORDER_ADDITION],
  // a && (b && c) -> a && b && c
  [ArduinoBlockly.ORDER_LOGICAL_AND, ArduinoBlockly.ORDER_LOGICAL_AND],
  // a || (b || c) -> a || b || c
  [ArduinoBlockly.ORDER_LOGICAL_OR, ArduinoBlockly.ORDER_LOGICAL_OR],
];

/**
 * Initialise the database of variable names.
 * @param {!Blockly.Workspace} workspace Workspace to generate code from.
 */
ArduinoBlockly.init = function (workspace: any) {
  // Create a dictionary of definitions to be printed at the top of the sketch
  ArduinoBlockly.includes_ = Object.create(null);
  // Create a dictionary of global definitions to be printed after variables
  ArduinoBlockly.definitions_ = Object.create(null);
  // Create a dictionary of variables
  ArduinoBlockly.variables_ = Object.create(null);
  // Create a dictionary of functions from the code generator
  ArduinoBlockly.codeFunctions_ = Object.create(null);
  // Create a dictionary of functions created by the user
  ArduinoBlockly.userFunctions_ = Object.create(null);
  // Create a dictionary mapping desired function names in definitions_
  // to actual function names (to avoid collisions with user functions)
  ArduinoBlockly.functionNames_ = Object.create(null);
  // Create a dictionary of setups to be printed in the setup() function
  ArduinoBlockly.setups_ = Object.create(null);

  if (!ArduinoBlockly.variableDB_) {
    ArduinoBlockly.variableDB_ = new Blockly.Names(
      ArduinoBlockly.RESERVED_WORDS_
    );
  } else {
    ArduinoBlockly.variableDB_.reset();
  }

  ArduinoBlockly.variableDB_.setVariableMap(workspace.getVariableMap());
  ArduinoBlockly.definitions_["variables"] = "";

  // get all types from all variable in workspace
  var allTypes = workspace.getVariableTypes();
  for (var i = 0; i < allTypes.length - 1; i++) {
    var defvars = [];
    // get all variable for one of this type
    var allVarOfType = workspace.getVariablesOfType(allTypes[i]);
    for (var j = 0; j < allVarOfType.length; j++) {
      defvars.push(allVarOfType[j].name);
    }
    ArduinoBlockly.definitions_["variables"] +=
      allTypes[i] + " " + defvars.join(", ") + ";\n";
  }
};

/**
 * Prepend the generated code with the variable definitions.
 * @param {string} code Generated code.
 * @return {string} Completed code.
 */
ArduinoBlockly.finish = function (code: any) {
  var includes = [],
    definitions = [],
    variables = [],
    functions = [],
    BLOCK_GLOBALS_ARRAY_SIZE = [];
  for (var name in ArduinoBlockly.includes_) {
    includes.push(ArduinoBlockly.includes_[name]);
  }
  if (includes.length) {
    includes.push("\n");
  }
  for (var name in ArduinoBlockly.definitions_) {
    definitions.push(ArduinoBlockly.definitions_[name]);
  }
  if (definitions.length) {
    definitions.push("\n");
  }
  for (var name in ArduinoBlockly.variables_) {
    variables.push(ArduinoBlockly.variables_[name]);
  }
  if (variables.length) {
    variables.push("\n");
  }
  for (var name in ArduinoBlockly.codeFunctions_) {
    functions.push(ArduinoBlockly.codeFunctions_[name]);
  }
  for (var name in ArduinoBlockly.userFunctions_) {
    functions.push(ArduinoBlockly.userFunctions_[name]);
  }
  if (functions.length) {
    functions.push("\n");
  }
  var setups = [""],
    userSetupCode = "";
  if (ArduinoBlockly.setups_["userSetupCode"] !== undefined) {
    userSetupCode = "\n" + ArduinoBlockly.setups_["userSetupCode"];
    delete ArduinoBlockly.setups_["userSetupCode"];
  }
  for (var name in ArduinoBlockly.setups_) {
    setups.push(ArduinoBlockly.setups_[name]);
  }
  if (userSetupCode) {
    setups.push(userSetupCode);
  }
  delete ArduinoBlockly.includes_;
  delete ArduinoBlockly.definitions_;
  delete ArduinoBlockly.codeFunctions_;
  delete ArduinoBlockly.userFunctions_;
  delete ArduinoBlockly.functionNames_;
  delete ArduinoBlockly.setups_;
  delete ArduinoBlockly.pins_;
  ArduinoBlockly.variableDB_.reset();
  var allDefs =
    includes.join("\n") +
    definitions.join("\n") +
    variables.join("\n") +
    functions.join("\n");
  var setup = "void setup() {" + setups.join("\n  ") + "\n}\n\n";
  var loop = "void loop() {\n  " + code.replace(/\n/g, "\n  ") + "\n}";
  // var setup = setups.join("\n  ");
  // var loop = code.replace(/\n/g, "\n  ");
  return allDefs + setup + loop;
};

/**
 * Naked values are top-level blocks with outputs that aren't plugged into
 * anything.  A trailing semicolon is needed to make this legal.
 * @param {string} line Line of generated code.
 * @return {string} Legal line of code.
 */
ArduinoBlockly.scrubNakedValue = function (line: any) {
  return line + ";\n";
};

/**
 * Encode a string as a properly escaped Arduino string, complete with quotes.
 * @param {string} string Text to encode.
 * @return {string} Arduino string.
 * @private
 */
ArduinoBlockly.quote_ = function (string: any) {
  // TODO: This is a quick hack.  Replace with goog.string.quote
  string = string
    .replace(/\\/g, "\\\\")
    .replace(/\n/g, "\\\n")
    .replace(/\$/g, "\\$")
    .replace(/'/g, "\\'");
  return '"' + string + '"';
};

/**
 * Common tasks for generating Arduino from blocks.
 * Handles comments for the specified block and any connected value blocks.
 * Calls any statements following this block.
 * @param {!Blockly.Block} block The current block.
 * @param {string} code The Arduino code created for this block.
 * @param {boolean=} opt_thisOnly True to generate code for only this statement.
 * @return {string} Arduino code with comments and subsequent blocks added.
 * @private
 */
ArduinoBlockly.scrub_ = function (block: any, code: any, opt_thisOnly: any) {
  if (code === null) {
    // Block has handled code generation itself.
    return "";
  }
  var commentCode = "";
  // Only collect comments for blocks that aren't inline.
  if (!block.outputConnection || !block.outputConnection.targetConnection) {
    // Collect comment for this block.
    var comment = block.getCommentText();
    if (comment) {
      commentCode += ArduinoBlockly.prefixLines(comment, "// ") + "\n";
    }
    // Collect comments for all value arguments.
    // Don't collect comments for nested statements.
    for (var x = 0; x < block.inputList.length; x++) {
      if (block.inputList[x].type == Blockly.INPUT_VALUE) {
        var childBlock = block.inputList[x].connection.targetBlock();
        if (childBlock) {
          var comment = ArduinoBlockly.allNestedComments(childBlock);
          if (comment) {
            commentCode += ArduinoBlockly.prefixLines(comment, "// ");
          }
        }
      }
    }
  }
  var nextBlock = block.nextConnection && block.nextConnection.targetBlock();
  var nextCode = ArduinoBlockly.blockToCode(nextBlock);
  return commentCode + code + nextCode;
};

export default ArduinoBlockly;

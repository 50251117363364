import "./styles/global.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/layouts/Layout";
import { Auth as UserContext } from "./contexts/ridersUserContext";
import { RedirectPage } from "./pages/redirectPage";
import RouteWithIntl from "./components/routeWithIntl";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RedirectPage />} />
        <Route
          path="/:token"
          element={
            <UserContext>
                <RouteWithIntl />
            </UserContext>
          }
        />
        <Route path="*" element={<Layout />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

import { VscMarkdown } from "react-icons/vsc";
import { TbBrandPython } from "react-icons/tb";
import { ImFileText2 } from "react-icons/im";
import { DiJavascript1 } from "react-icons/di";
import { AiOutlineBlock } from "react-icons/ai";
import { GoBrowser } from "react-icons/go";
import { BsPlay, BsFillQuestionDiamondFill } from "react-icons/bs";
import { SiArduino } from "react-icons/si";

export const IconSelector = (fileType: string) => {
  switch (fileType) {
    case "python":
      return <TbBrandPython />;
    case "javascript":
      return <DiJavascript1 />;
    case "arduino":
      return <SiArduino />;
    case "blockly":
      return <AiOutlineBlock />;
    case "arduinoBlockly":
      return <AiOutlineBlock />;
    case "text":
      return <ImFileText2 />;
    case "markdown":
      return <VscMarkdown />;
    case "iframe":
      return <GoBrowser />;
    case "simulation":
      return <BsPlay />;
    default:
      return <BsFillQuestionDiamondFill />;
  }
};

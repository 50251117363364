import React, { useState, useEffect, useRef } from "react";
import { IDropdownComponentProps } from "../types";

const Dropdown: React.FC<IDropdownComponentProps> = ({
  children,
  className,
  menu,
  type = "click",
  position = "top-0 left-full",
}) => {
  const [open, setOpen] = useState(false);
  const compRef = useRef<HTMLDivElement>(null);

  const handleOuterClick = (event: MouseEvent) => {
    if (compRef.current && !compRef.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  const clickAndCloseHandler: (func: () => void) => any = (func) => {
    return () => {
      func();
      setOpen(false);
    };
  };

  useEffect(() => {
    if (type === "click") {
      if (open) {
        document.addEventListener("click", handleOuterClick);
        return () => {
          document.removeEventListener("click", handleOuterClick);
        };
      }
    }
  }, [open]);

  return (
    <div
      ref={compRef}
      className="relative"
      onMouseLeave={() => {
        if (type === "hover") {
          setOpen(false);
        }
      }}
    >
      <span
        onClick={() => {
          if (type === "click") {
            setOpen(!open);
          }
        }}
        onMouseEnter={() => {
          if (type === "hover") {
            setOpen(true);
          }
        }}
      >
        {children}
      </span>
      {open && (
        <div
          className={`absolute bg-vs-light-panel dark:bg-vs-dark-panel border border-vs-light-border dark:border-vs-dark-border rounded-md shadow-lg ${className} ${position}`}
          style={{ minWidth: "200px",  zIndex: 71}} // Z-index is 71 because the Blockly workspace has a z-index of 70
        >
          {React.cloneElement(menu, { clickAndCloseHandler })}
        </div>
      )}
    </div>
  );
};

export { Dropdown };

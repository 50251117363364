import React, { useContext, useEffect, useState } from 'react';
import { IntlProvider } from "react-intl";
import translations from "../translations";
import Layout from "./layouts/Layout";
import {Auth} from "../contexts/ridersUserContext";
import useLanguage from "./useLanguage";

const RouteWithIntl = () => {
    const { project } = useContext<any>(Auth.context);
    const {language} = useLanguage(project);

    return (
        <IntlProvider locale={language} messages={translations[language]}>
            <Layout />
        </IntlProvider>
    );
};

export default RouteWithIntl;

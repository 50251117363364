import React, { FC } from "react";
import { useContext } from "react";
import { Screen } from "./";

export const MiniBrowser: FC<any> = ({
  src,
  title,
  refrence,
  className,
  style,
}) => {
  const { isDragging, refreshKey } = useContext<any>(Screen.context);

  return (
    <div
      className={`fit relative ` + className}
      style={{ ...style }}
      key={refreshKey}
    >
      {isDragging && <div className="fit absolute"></div>}
      <iframe
        src={src}
        style={{
          borderLeft: "black 1px solid",
          borderRight: "black 1px solid",
        }}
        className="fit"
        title={title}
        allowFullScreen
        allow="fullscreen; cross-origin-isolated; clipboard-read; clipboard-write; camera *; microphone *;"
        ref={refrence}
      />
    </div>
  );
};

import React from "react";
import { IMenuProps } from "../types";
import { Dropdown, Space } from "../";
import { AiOutlineRight } from "react-icons/ai";

export const Menu: React.FC<IMenuProps> = ({ children }) => {
  return (
    <div className="flex flex-col w-full p-1 text-vs-dark-text">
      {children?.map((item, index) => {
        if(item.disabled) return null;
        if (!item.title && !item.component) {
          return (
            <span className="w-full h-5 flex items-center p-2" key={index}>
              <span className="w-full h-px bg:vs-light-text dark:bg-vs-dark-text"></span>
            </span>
          );
        } else if (item.component) {
          return (
            <div
              className="w-full pl-5 pr-2 text-xs font-medium capitalize flex items-center rounded-md hover:bg-riders-green hover:text-vs-dark-input"
              key={index}
              style={{ minWidth: 20 * 8 + 20 }}
            >
              {item.component}
            </div>
          );
        } else if (!item.items) {
          return (
            <div
              className="w-full pl-5 pr-2 text-xs font-medium capitalize flex items-center rounded-md hover:bg-riders-green hover:text-vs-dark-input"
              key={index}
              onClick={item.onClick}
              style={{ minWidth: item.title!.toString()!.length * 8 + 20 }}
            >
              {item.icon}
              {item.title}
              <Space />
              {item.shortcut}
            </div>
          );
        } else {
          return (
            <Dropdown
              menu={<Menu>{item.items}</Menu>}
              key={index}
              type="hover"
              position="left-full -top-1"
            >
              <div
                className="w-full pl-5 pr-2 text-xs font-medium capitalize flex items-center rounded-md hover:bg-riders-green hover:text-vs-dark-input"
                onClick={item.onClick}
                style={{ minWidth: item.title!.toString()!.length * 8 + 20 }}
              >
                {item.icon}
                {item.title}
                <Space />
                <AiOutlineRight />
              </div>
            </Dropdown>
          );
        }
      })}
    </div>
  );
};

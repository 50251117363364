import React, { useState, useContext } from "react";
import {
  VscDebugAlt,
  VscMenu,
  VscFiles,
  VscSettingsGear,
  VscTerminal,
} from "react-icons/vsc";
import { GiConfirmed } from "react-icons/gi";

import { TiTick } from "react-icons/ti";
import { BsArrowRightShort, BsCpu } from "react-icons/bs";
import { GrDocumentText } from "react-icons/gr";
import { HiUpload, HiDownload, HiOutlineDocumentText } from "react-icons/hi";
import { AiOutlineFileText } from "react-icons/ai";

import { IoReturnDownBack } from "react-icons/io5";
import { RidersIcon, Space } from "../shared";
import { ToolbarIcon, Dropdown, Screen } from "./";
import { IToolbarComponentProps } from "./types";
import MainMenu from "./menu/MainMenu";
import SettingsMenu from "./menu/SettingsMenu";
import Explore from "./extensions/Explore";
import Riders from "./extensions/Riders";
import Git from "./extensions/Git";
import { Auth } from "../../contexts/ridersUserContext";

import { saveAs } from "file-saver";

import { FileSystem } from "./";
import { message } from "antd";
import { Communication } from "../../contexts/communicationContext";

import LFS from "@isomorphic-git/lightning-fs";

const fs = new LFS("riders_fs");

export const Toolbar: React.FC<IToolbarComponentProps> = ({
  setPanel,
  panel,
  className,
}) => {
  const {
    isTerminalOpen,
    setIsTerminalOpen,
    godotContainerRef,
    env,
    project,
    refreshFunction,
    community,
  } = useContext<any>(Screen.context);

  const { projectType, userCodeFilePath } = useContext<any>(Auth.context);

  const { readFile, writeFile } = useContext<any>(FileSystem.context);
  const { parentPostMessage } = useContext<any>(Communication.context);

  const [terminalStatus, setTerminalStatus] = useState<boolean>(false);

  var fileSelector: HTMLInputElement | undefined = undefined;

  const [compiling, setCompiling] = useState<boolean>(false);

  const handleUpload = (
    onFileLoaded: (e: ProgressEvent<FileReader>) => void
  ) => {
    if (typeof fileSelector === "undefined") {
      fileSelector = document.createElement("input");
      fileSelector.type = "file";
      fileSelector.style.display = "none";
      document.body.appendChild(fileSelector);
    }
    fileSelector.onchange = (e) => {
      if (!fileSelector?.files || fileSelector?.files?.length !== 1) return;

      let reader = new FileReader();
      reader.readAsText(fileSelector?.files[0], "UTF-8");

      reader.onload = onFileLoaded;

      reader.onerror = (e) => {
        // message.error("File could not imported", 2);
      };

      document.body.removeChild(fileSelector);
    };

    fileSelector.click();
  };

  const handleDownload = async (file_path: string, save_name: string) => {
    const code = await readFile(file_path);
    console.log(code);
    const blob = new Blob([code], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blob, save_name);
  };

  //add post message listener

  window.addEventListener("message", (e) => {
    try {
      const data = JSON.parse(e.data);
      if (data.type === "openTerminal") {
        setTerminalStatus(true);
      }

      if (data.type === "closeTerminal") {
        setTerminalStatus(false);
      }
    } catch (error) {
      return;
    }
  });

  return (
    <div
      className={`h-full w-12 bg-arduino-green-dark dark:bg-vs-dark-toolbar flex flex-col p-2 dark:p-0 ${className}`}
    >
      {community === "deneyap" && (
        <ToolbarIcon
          Icon={GiConfirmed}
          onClick={async (e: any) => {
            await parentPostMessage({
              action: "notify_progression",
              progress: 100,
            });
            message.success(
              "Test Post Message Sent : " +
                JSON.stringify({
                  action: "notify_progression",
                  progress: 100,
                }),
              2
            );
          }}
        />
      )}
      {projectType === "robotics" && (
        <Dropdown menu={<MainMenu />}>
          <ToolbarIcon Icon={VscMenu} height="h-9" size="text-base" />
        </Dropdown>
      )}

      {/* <Explore setPanel={setPanel} panel={panel} /> */}

      {/* <ToolbarIcon
        Icon={VscDebugAlt}
        onClick={(e: any) => {
          extensionClickHandler("Debug", panel, setPanel);
        }}
        active={panel.title === "Debug" && panel.open}
      /> */}

      {/* <Riders setPanel={setPanel} panel={panel} /> */}

      {project?.git_address && (
        <>
          <Git setPanel={setPanel} panel={panel} />
        </>
      )}

      <ToolbarIcon
        Icon={VscTerminal}
        active={terminalStatus}
        activeColor="fill-riders-green-dark dark:fill-riders-green"
        activeBorderColor="border-riders-green-dark dark:border-riders-green"
        onClick={(e: any) => {
          setTerminalStatus(!terminalStatus);
          const message = JSON.stringify({
            action: "swichTerminal",
          });
          godotContainerRef.current?.contentWindow.postMessage(message, "*");
        }}
      />

      {projectType === "electronics" && (
        <>
          {project.dev === "code" && (
            <ToolbarIcon
              Icon={AiOutlineFileText}
              onClick={async (e: any) => {
                const code = await readFile(userCodeFilePath);
                navigator.clipboard.writeText(code);
                message.success("Code copied to clipboard", 2);
              }}
            />
          )}
          <ToolbarIcon
            Icon={HiUpload}
            onClick={(e: any) => {
              handleUpload(async (e) => {
                const data = e?.target?.result;
                writeFile(
                  `${
                    project.dev === "blockly"
                      ? "/blockly.xml"
                      : userCodeFilePath
                  }`,
                  data
                );
                //settimeout
                setTimeout(() => {
                  refreshFunction();
                }, 1000);
              });
            }}
          />
          <ToolbarIcon
            Icon={HiDownload}
            onClick={(e: any) => {
              handleDownload(
                `/${
                  project.dev === "blockly" ? "blockly.xml" : userCodeFilePath
                }`,
                `${project.name}_${
                  project.dev === "blockly"
                    ? "blockly.xml"
                    : userCodeFilePath.split("/").pop()
                }`
              );
            }}
          />

          {community !== "deneyap" && (
            <ToolbarIcon
              onClick={async () => {
                var isChrome = typeof (window as any).chrome !== "undefined";

                if (!isChrome) {
                  message.info({
                    content: "Please use Chrome for compiling",
                    key: "hex",
                    duration: 2,
                  });
                }

                if (compiling || !isChrome) {
                  return;
                }

                setCompiling(true);

                message.loading({ content: "Compiling", key: "hex" });

                const code = await readFile(userCodeFilePath);
                let uri =
                  "https://141euep68f.execute-api.eu-west-1.amazonaws.com/default/ino2HexConverter";
                let ino_content = JSON.stringify({ ino_content: code });
                let response = await fetch(uri, {
                  method: "POST",
                  body: JSON.stringify({ body: ino_content }),
                  headers: {
                    "Content-Type": "application/json",
                  },
                }).then((resp) => resp.json());

                const body = JSON.parse(response.body);

                if (body.hex) {
                  try {
                    await fs.promises.stat("/.build", undefined);
                  } catch (error) {
                    await fs.promises.mkdir("/.build");
                  }

                  await fs.promises.writeFile(
                    "/.build/ino.hex",
                    body.hex,
                    "utf8"
                  );

                  message.loading({
                    content: "Compiled successfully uploading to device",
                    key: "hex",
                  });

                  window.document.getElementById("uploadhex")?.click();

                  setCompiling(false);
                  message.success({
                    content: "Compile success",
                    key: "hex",
                    duration: 2,
                  });
                } else {
                  message.error({
                    content: "Compile failed",
                    key: "hex",
                    duration: 2,
                  });
                  setCompiling(false);
                }
              }}
              Icon={BsCpu}
            />
          )}
        </>
      )}

      <Space />

      {env === "edusim" && (
        <>
          <img src="/edusim.png" className="px-1" alt="edusim_logo" />
          <img src="/meb_logo.png" className="px-1 my-3" alt="meb_logo" />
        </>
      )}

      {projectType === "robotics" && (
        <ToolbarIcon
          Icon={IoReturnDownBack}
          onClick={
            env === "edusim"
              ? (e: any) => {
                  window.close();
                }
              : (e: any) => {
                  window.location.href = "https://riders.ai";
                }
          }
        />
      )}

      {/*
      <Dropdown menu={<SettingsMenu />} position="left-full bottom-1">
        <ToolbarIcon Icon={VscSettingsGear} />
      </Dropdown> */}
    </div>
  );
};

const extensionClickHandler = (
  title: string,
  panel: any,
  setPanel: (panel: any) => void,
  children?: any,
  settings?: any
) => {
  if (panel.title === title) {
    setPanel({ ...panel, open: !panel.open });
  } else {
    setPanel({ title, open: true, children, settings });
  }
};
